@import '../../../styles/_settings';
@import '../../../styles/_tables';

.draft-list {
  background-color: $pv-background-sub-colour;
  .guide {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: -40px;
    margin-left: 90px;

    .fa, .icon {
      margin-left: 10px;
    }
  }
  
  .vertical-spacer{
    height: 170px;
  }
  
  .title-container{
    color: $pv-text-colour-80;

    h1.title{
      padding: 20px 0 20px 20px !important;
      margin-bottom: 0;
    }
  }
}
.drafts-responses {
  position: relative;
  //margin-top: 170px;

  table {
    @extend %table;
  }
}


