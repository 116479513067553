@import '../../../../styles/_settings';
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.home-tile-strip {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0 20px 20px;
    position: absolute;
    top: 112px;

    @include media-breakpoint-down(sm) {
        padding: 20px;
    }
}