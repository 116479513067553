@import '../../../styles/variables';

.modal {
    .modal-dialog {
        transform: translate(0,-50%);
        top: 35%;
        margin: 0 auto;

        @include media-breakpoint-down(sm) {
            top: 15%;
        }
    }
    
    .modal-title, .modal-body{
        color: $pv-text-colour-80;
    }
}