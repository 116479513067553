@import '../../../../styles/_settings';

.table_options{

    .column_menu{
        color: $pv-primary-colour;
        font-weight: 500;
        display: flex;

        .menu_item{
            margin: 10px;
            display: flex;
            align-items: baseline;
            gap: 5px;

            &:hover{
                cursor: pointer;
            }

            .icon-rotate{
                display: inline-block;
                transform: rotate(90deg);
            }
        }

        .disabled{
            cursor: default;
            pointer-events: none;
            color: $panviva-darkgray;
        }
    }
}
.selection_popup{
    display: flex !important;
    padding: 0;
    .selection_checkbox {
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    .select_all{
        padding-top: 10px;
        border-top: 1px solid grey;
    }
}