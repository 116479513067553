@keyframes fa-spin-counterclockwise {
    0% {
              transform: rotate(0deg); }
    100% {
              transform: rotate(-360deg); } }

@keyframes oilcan {
    0% {
        transform: rotateZ(0deg) rotateY(180deg);
    }

    100% {
        transform: rotateZ(-25deg)  rotateY(180deg);
    }
}

@keyframes drip {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(60px);
    }
}

.error-page {
    p {
        font-size: 20px;
    }

    .error-icon {
        width: 100px;
        height: 140px;
        margin-bottom: 35px;
        position: relative;

        .fa-oil-can {
            position: absolute;
            top: 0;
            right: 12px;
            font-size: 40px;
            width: 40px;
            height: 40px;

            animation: oilcan 1s ease-out;
            animation-fill-mode: forwards;
        }

        .fa-tint {
            font-size: 10px;
            animation: drip 0.7s ease-in infinite;
            position: absolute;
            top: 42px;
            left: 44px;
        }

        .cog-sm {
            position: absolute;
            font-size: 30px;
            width: 30px;
            height: 30px;
            top: 60px;
            left: 10px;
        }

        .cog-lg {
            position: absolute;
            bottom: 0;
            font-size: 60px;
            width: 60px;
            height: 60px;
            left: 50%;
            margin-left: -20px;

            &.fa-spin {
                animation: fa-spin-counterclockwise 2s infinite linear;
            }
        }
    }

    .back-btn {
        margin-top: 60px;
    }
}

