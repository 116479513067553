@import "../../../../styles/variables.scss";

.response-variation-box {
  .validation-error {
    padding-top: 5px;
    display: none;
    font-size: 14px;
    color: $required-flag-color;

    &.invalid {
      display: block;
    }
  }

  .response-variation {
    width: 100%;
    border: 2px solid $response-variation-box-border-color;
    border-radius: 4px;
    margin-bottom: 20px;

    .variation-header {
      padding: 11px 21px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: $pv-gray15;

      .header {
        display: flex;
        font-size: 14px;
        font-weight: 500;

        .refresh-icon{
          margin-left: 5px;
          cursor: pointer;
        }
      }

      .wandicon {
        display: inline-block;
        width: 29px;
        height: 29px;
        border: 2px solid #2474DB;
        overflow: hidden;
        border-radius: 4px;
        cursor: pointer;
        margin: -2px 5px;

        &.disabled {
          border: 2px solid #6B7786;
        }

        img {
          margin: -8px;
        }
      }

      .fa, .icon {
        font-size: 21px;
      }
    }



    .content {
      background-color: $pv-blue20;
      padding: 16px 20px;
      position: relative;

      .refresh-icon {
        position: absolute;
        right: 25px;
        bottom: 15px;
      }

      textarea {
        font-size: 14px;
        border: 1px solid $panviva-border-gray;
        border-radius: 0;
        padding-right: 25px;
      }

      .scroll + .refresh-icon {
        right: 38px;
      }

      &.is-invalid {
        textarea {
          border: 1px solid $required-flag-color;
        }

        .invalid-feedback {
          display: block;
        }
      }
    }

    .channels {
      padding: 10px 20px;

      .header {
        display: flex;
        flex-direction: row;
        align-items: center;

        .field-label {
          font-size: 14px;
          margin-right: 5px;

          &:after {
            content: '*';
            color: $required-flag-color;
            margin-left: 2px;
            position: relative;
            top: -.3em;
          }
        }

        .components-icon-close{
          margin-left: auto;
          font-size: x-large;
        }
      }

      .channel-selection {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .dropdown {
          margin-top: 10px;

          .dropdown-menu {
            height: auto;
            max-height: 200px;
            overflow-x: hidden;
          }

          .btn-outline-secondary {
            font-size: 16px;
            margin-right: 10px;
            margin-bottom: 10px;
            border: 1px solid $panviva-border-gray;
            display: inline-flex;
            justify-content: space-between;
            align-items: center;

            &:hover,
            &:focus {
              color: $channel-selector-color;
              background-color: transparent;
              border-color: $panviva-border-gray;
              box-shadow: none;
            }

            &.is-invalid {
              border: 1px solid $required-flag-color;
            }
          }

          .dropdown-item {
            cursor: pointer;
          }
        }

        .selected-channels {

          display: inline-flex;
          flex-wrap: wrap;
          align-items: flex-end;

          .channel {
            margin-right: 10px;
            margin-bottom: 13px;
            padding: 4px 10px 6px 10px;
            border-radius: 20px;
            background-color: $pv-tag-bg-colour;
            font-size: 14px;
            color: $pv-text-colour-80;
            height: 31px;
            border: 1px solid $pv-text-colour-80;

            .fa, .icon {
              margin-left: 5px;
            }
          }
        }
      }

    }
  }
}