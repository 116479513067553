.source-table {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 90%;
    padding: 0px 20px 0px 20px;

    .source-table-container{
        border: none;
        overflow-y: hidden;
            .source-table-heading{
                font-size: 12px;
                color:#64748B;    
            }
            .source-table-data{
                color: #191D23;
                font-size: 14px;
                border-bottom: none;
                    .source-path-icon{
                        margin-left: 20px;
                        color: #191D23;
                        text-decoration: none !important;
                    }
                    .source-cell-link{
                        display: inline-block;
                        color: #191D23;
                        text-decoration: underline;
                        max-width: 300px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .source-cell-path{
                        display: flex;
                        align-items: baseline;
                        >span{
                            display: inline-block;
                            width: 220px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            direction: rtl;
                        }    
                    }    
                    .source-cell-name{
                        width: 100px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }                 
            }
            .source-table-tags-data{
                display: grid;
                column-gap: 10px;
                row-gap: 5px;
                padding: 5px;
                grid-template-columns: 75px 75px;                
                    .source-cell-tags{
                        color: #0B699D;
                        background-color: #ECF6FD;
                        padding: 3px;
                        font-weight: 600;
                        border-radius: 3px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }                            
            }
        }
}

.source-tooltip{
    min-width: max-content;
}

.source-table-error{
    display: table-caption;
    margin-top: 20px;
    text-align: center;
}