@import '../../../styles/settings';

.search-bar {
    .title {
        display: flex;
        margin-bottom: 20px;
        font-weight: normal;
        font-size: 16px;

        .icon-info-circle {
            margin-left: 10px;
        }
    }

    .search-row {
        display: flex;
        margin-bottom: 20px;

        .search-group {
            margin-top: 0px;
            width: 30%;
        }

        .input-group {
            width: 60%;
            min-width: 600px;
            margin-right: 10px;

            .form-control {
                font-size: 14px;
            }

            .input-group-append {
                &:not(:last-child) {
                    .btn {
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
                }
            }

            .reset-btn {
                margin-left: 10px;
            }
        }

        .input-group-append .btn, .btn {
            font-size: 12px;
            //height: calc(2.875rem + 2px);
            height: 2.65rem;
        }
    }
}