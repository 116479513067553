@import '../../styles/_settings';
@import '../../styles/_tables';

.updates {
  position: relative;
  margin-top: 0;

  table {
    @extend %table;
  }
  .message{
    position: absolute;
    top: 23px;
    left: 122px;
  }
  .vertical-spacer{
    height: 118px;
  }
  .update-list{
    background-color: $pv-light-colour;
    margin-left: 20px;
    padding-top: 0;
    width: calc(100% - 40px);
  }
  .title-link {
    color: $pv-primary-colour;
  }
  .table tr {
    height: 47px;
    font-size: 14px;
    border-bottom: 0.5px solid $pv-border-colour;
  }
}
.updates-container {
  background-color: $pv-background-sub-colour;

  .pagination-container{
    background-color: $pv-light-colour;
      margin-left: 20px;
      width: calc(100% - 40px);
      position: relative;
      top: -14px;
      margin-bottom: 20px;
      padding-top: 13px;
  }
}