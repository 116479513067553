@import '../../../styles/variables';

.sources-container {
    
    width: 100%;

    .sources-title{
        padding: 20px;
        border-bottom: 1px solid $pv-border-colour;
        background: $pv-light-colour;
        font-size: 18px;
        font-weight: 600;
        color: #265173;
    }
    .sources-page {
        background-color: white;
        margin: 0.5% 7% 0.5% 7%;
        padding: 0.5%;
        border-radius: 1%;
        }
}