@import '../../../styles/_settings';

.up-to-date-modal {
    height: 344px;
    width: 100%;
    max-width: 695px;
    border: 1px solid $panviva-border-gray;
    border-radius: 4px;
    background-color: rgba(255,255,255,0.8);

    left: 50%;
    top: 65%;
    position:absolute;
    transform:translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    .empty-list-icon {
      padding-top: 61px;
      padding-bottom: 34px;
      font-size: 100px;
    }

    .up-to-date-heading {
      font-weight: 300;
      margin-bottom: 1rem;
    }
  }