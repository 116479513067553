@import "../../../../styles/font_variables";
@import "../../../../styles/variables.scss";

.analytics-table {
    position: relative;
    justify-content: center;
    width: 100%;
    height: 90%;

    .analytics-table-container{
        border: none;
        height: 75vh;
        overflow: hidden;
        table{
            display: block;
            overflow: auto;
        }
        table th[aria-sort="descending"] .sort-icon::after {
            content: $icon-arrow-up;
            font-size: 18px;
            font-weight: 400;
          }                  
        table th[aria-sort="ascending"] .sort-icon::after {
            content: $icon-arrow-down;
            font-size: 18px;
            font-weight: 400;
          }
        table th:not([aria-sort]) span:focus .sort-icon::after,
        table th:not([aria-sort]) span:hover .sort-icon::after {
            content: $icon-arrow-down;
            font-size: 18px;
            font-weight: 400;
            }
        .analytics-table-header{
            position: sticky;
            top: 0;
            background-color: $pv-background-sub-colour;
            .analytics-table-heading{
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-width: 200px;
                font-size: 12px;
                color:#64748B;    
            }
        }
        .analytics-table-data{
                color: #191D23;
                font-size: 14px;
                border-bottom: none;
                    .analytics-cell-name{
                        width: 180px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }                 
            }
        .analytics-table-tags-data{
                display: grid;
                column-gap: 10px;
                row-gap: 5px;
                padding: 5px;
                grid-template-columns: 75px 75px;                
                    .analytics-cell-tags{
                        color: #0B699D;
                        background-color: #ECF6FD;
                        padding: 3px;
                        font-weight: 600;
                        border-radius: 3px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-wrap: nowrap;
                    }                            
            }
        }
}

.analytics-tooltip{
    max-width: 600px !important;
}

.analytics-table-error{
    display: table-caption;
    margin-top: 20px;
    text-align: center;
}