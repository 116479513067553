@import '../../../../styles/_settings';

.urls-accordion {
  margin-bottom: 12px;
}

.response-urls {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .error {
      margin-bottom: 5px;
      margin-right: 60%;
      margin-left: 0;
      height: 38px;
      padding: 3px;
      border-radius: 4px;
      color: #f50606;
      font-size: 16px;
    }

    .add-urls {
        min-width: 200px;
        margin-bottom: 10px;
        height: 38px;

        .form-control {
          height: 38px;
          border: 1px solid $panviva-border-gray;
        }
        .btn {
            background-color: $keyword-button-bg-color;
            border: 1px solid $panviva-border-gray;
            border-radius: 0 4px 4px 0;
            height: 38px;
        }
    }

    .url {
        margin-bottom: 10px;
        margin-right: 10px;
        margin-left: 0;
        height: 38px;
        padding: 15px;
        display: inline-flex;
        align-items: center;
        background-color: #E1ECF4;
        border-radius: 4px;
        font-size: 16px;

        .fa {
            cursor: pointer;
            margin-left: 5px;
        }
    }
}