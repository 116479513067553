@import '../../../styles/variables';

.search-responses {
    width: 100%;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;

    @include media-breakpoint-down(sm) {
        width: 80%;
    }

    .page-title {
        display: flex;
        flex-direction: row;
        border-bottom: 2px solid $border-color;
        background-color: $pv-light-colour;
        padding: 15px 0 8px 16px;
        margin-top: -29px;
        margin-left: -19px;
        
        h1 {
            font-size: 18px;
            font-weight: 600;
            line-height: 18px;
        }
    }

    .input-group.search-group {
      width: 30%;
      min-width: 500px;
    }
}