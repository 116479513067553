@import "_base";
@import "_forms_shared";

body{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.body-title{
    height: 55px;
    display: flex;
    padding: 14px;
    background-color: $pv-light-colour;
    font-size: 16pt;
    font-weight: 500;
    border-bottom: 1.5px solid $panviva-border-gray;
}
.btn{
    letter-spacing: 1.5px;
    font-size: 14px;

    &:focus {
        box-shadow: 0 0 0 .2rem rgba(0,123,255,.5);
    }

}
.btn-primary{
    background-color: $pv-primary-colour;
    color: $pv-light-colour;
    text-transform: uppercase;
    border: 1.5px solid $pv-primary-colour;

    &:hover{
        background-color: $pv-primary-colour;
        color: $pv-light-colour;
        border: 1.5px solid $pv-primary-colour;
    }
}
.btn-outline-secondary{
    color: $pv-primary-colour;
    text-transform: uppercase;
    font-weight: 500;
    border: 1.5px solid $pv-primary-colour;

    &:hover, &:active, &:focus, &.show {
        background-color: $pv-light-colour;
        color: $pv-primary-colour;
        border-color: $pv-primary-colour;
    }
}

.btn-outline-secondary:not(:disabled):not(.disabled):active{
        background-color: $pv-light-colour;
        color: $pv-primary-colour;
        border-color: $pv-primary-colour;
}

.alert_container{
    left: 57px;
    bottom: 25px;
    position: fixed;
    background-color: #d8dee5 !important;
}

.progresss_container{
    left: 75px;
    bottom: 25px;
    position: fixed;
    box-shadow: 2px 2px #000000;

    .progress_header{
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
        font-weight: 500;

        svg{
            color: $pv-primary-colour;
        }
    }

    .progress_status{
        padding-left: 35px;

        :first-child:last-child{
            margin-bottom: 10px;
        }
    }
}
