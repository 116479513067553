@import "../../../styles/_settings";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

header {
  box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.5);
  z-index: 2;
  padding: 0 20px;
  background-color: $pv-primary-colour;

  .page-header {
    max-height: 60px;
    background-color: $pv-primary-colour;
    color: $pv-light-colour;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    .main-menu {
      cursor: pointer;
      .collapse-menu {
        display: none;
      }
      .icon-menu,
      .collapse-menu {
        font-size: 18pt;
      }
      .heading {
        font-size: 16pt;
        margin-left: 23px;
      }
    }

    .logo-section {
      display: inline-flex;
      flex-direction: row;
      align-items: baseline;

      .logo {
        width: 134px;
        height: 31px;
        display: inline-block;
        vertical-align: bottom;
        background: transparent url(/images/upland-panviva-logo-white.svg)
          no-repeat left top;
        background-size: contain;
        background-size: 96pt;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        flex-direction: column;
      }

      .heading {
        font-size: 27px;
        line-height: 32px;
        margin-bottom: 0px;
      }
    }

    .user-section {
      text-align: right;
      //padding-right: 43px;
      float: right;

      .icon {
        font-size: 20px;
        line-height: 20px;
        vertical-align: bottom;
        padding: 0 2px;
      }
      .logo {
        width: 134px;
        height: 31px;
        //display: inline-block;
        vertical-align: bottom;
        background: transparent url(/images/upland-panviva-logo-white.svg)
          no-repeat left top;
        background-size: contain;
        background-size: 96pt;
        float: left;
        margin-right: 30px;
      }

      @include media-breakpoint-down(sm) {
        padding-right: 10px;
      }

      .dropdown {
        display: inline-block;

        .user-avatar {
          vertical-align: middle;
          width: 20px;
          height: 20px;
          line-height: 20px;
          color: $text-color;
          display: inline-block;
          font-size: 20px;
        }

        .dropdown-toggle {
          cursor: pointer;
          color: $pv-light-colour;
          
          &.show {
              &::after {
                transform: rotate(180deg);
              }            
  
            .dropdown-menu {
              @include media-breakpoint-down(sm) {
                margin-left: -150px;
                min-width: 200px;
                margin-top: -10px;
              }
            }
          }

          &::after {
            transition: transform 0.3s;
            vertical-align: 0.16em;
            border-top: 0.4em solid;
            border-right: 0.4em solid transparent;
            border-bottom: 0;
            border-left: 0.4em solid transparent;
          }

          @include media-breakpoint-down(sm) {
            &::after {
              border: none;
            }
            .fa-user-circle {
              font-size: 3rem;
            }
          }
        }       

        .dropdown-menu {
          min-width: 100%;
          vertical-align: middle;
          margin-top: 10px;
          a {
            color: $text-color;
          }

          .dropdown-item:hover {
            background-color: $dropwdown-hover-bg;
          }
        }

        .user-name {
          padding-left: 10px;
          padding-right: 20px;
          &:hover{
            text-decoration: underline;
          }
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
      }
    }
    .tenant-selector {
      text-align: left;
      float: left;
      border-left: 2px solid $pv-light-colour;
      padding: 0 33px 0 30px;

      @include media-breakpoint-down(sm) {
        padding-right: 10px;
      }

      .dropdown {
        display: inline-block;

        .dropdown-toggle {
          cursor: pointer;

          &.show {
              &::after {
                transform: rotate(180deg);
              }            
  
            .dropdown-menu {
              @include media-breakpoint-down(sm) {
                margin-left: -50px;
                min-width: 100px;
                margin-top: -10px;
              }
            }
          }

          &::after {
            transition: transform 0.3s;
            vertical-align: 0.16em;
            border-top: 0.4em solid;
            border-right: 0.4em solid transparent;
            border-bottom: 0;
            border-left: 0.4em solid transparent;
          }

          @include media-breakpoint-down(sm) {
            &::after {
              border: none;
            }
            .fa-user-circle {
              font-size: 3rem;
            }
          }
        }        

        .dropdown-menu {
          min-width: 100%;
          vertical-align: middle;
          margin-top: 10px;
          a {
            color: $text-color;
          }

          .dropdown-item:hover {
            background-color: $dropwdown-hover-bg !important;
          }
        }
      }
    }
    .dropdown {
      padding-top: 6px;
    }
  }
}
