$panviva-teal: #40BCA1;
$panviva-navy: #265173;
$panviva-red: #DC3545;
$panviva-darkgray: #e4e5e6;

$font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
$body-background-color: #FFF;
$text-color: $panviva-navy;
$grey-text-color: #5c5c5c;
$border-color: #D8D8D8;

$header-color: #000;
$dark-grey: #C8CACC;
$button-dark-bg: #5c5c5c;
$dark-element-font-color: #fff;
$light-element-font-color: #000;
$dark-box-bg: #5c5c5c;
$dropwdown-hover-bg: #ececec;
$container-width: 1300px;
$title-color: $panviva-navy;

$panviva-border-gray: #BFC6CE;

$pv-primary-colour: #2574DB;
$pv-background-colour: #FFF;
$pv-background-sub-colour: #F1F3F3;
$pv-light-colour: #FFF;
$pv-dark-colour: #000;
$pv-danger-colour: #E60C51;
$pv-icon-colour: #6B7786;
$pv-text-colour-80: #252b31;
$pv-row-hover: #F1F3F3;
$pv-published-response: #5fcae7;
$pv-draft-response: #6b7786;
$pv-text-inside-colour:#252b31;
$pv-border-colour:#bfc6ce;
$pv-border-darker-colour:#838fa0;
$pv-tag-bg-colour:#bfc6ce;
$pv-danger-colour: #e60c51;
$pv-blue10:#e2eefd;
$pv-gray15:#d4d9dd;
$pv-blue20:#bcd8fc;
$pv-borders: #6b7786;


$green-shade-color: rgba(64,188,161,0.4);
$btn-primary-bg-color: $pv-primary-colour;
$btn-primary-hover-bg-color: #38A38C;
$btn-primary-text-color: #FFF;
$btn-disabled-bg-color: #E4E5E6;
$btn-disabled-text-color: #485056;

$table-row-hover-color: #485056;
$table-row-header-color: #1B3147;
$table-text-color: #485056;

$pagination-active-item-bg-color: #EFEFEF;
$pagination-active-item-text-color: #9B9B9B;
$pagination-bg-color: #F9F9F9;

$notification-count-diameter: 25px;

$sidebar-text-color: #FFF;
$sidebar-background-color: #9EC5E0;
$sidebar-highlight-color: #E2EEFD;
$sidebar-inactive-item-color: $panviva-navy;

$timeline-item-bg: $panviva-navy;
$timeline-item-font-color: #fff;

$tooltip-bg-color: $panviva-navy;

$note-box-border: #485056;

$manage-doc-info-color: #485056;
$manage-form-input-color: #1B3147;

$selectdoc-input-border: $panviva-border-gray;
$selectdoc-input-color: #1B3147;
$selectdoc-copybtn-bg: $panviva-navy;
$selectdoc-copybtn-color: #FFF;

$sectionselect-bg: #FFF;
$sectionselect-hover-bg: #485056;
$sectionselect-checkbox-bg: #FFF;
$sectionselect-checkbox-bg-checked: $panviva-navy;
$sectionselect-checkbox-bordercolor: $panviva-navy;

$steppanel-bg: #d8ede6;
$steppanel-color: #485056;
$steppanel-heading-color: #252b31;
$steppanel-heading-bg: #3a5271;

$required-flag-color: $panviva-red;

$notification-bg-color: #FFF3CD;
$notification-border-color: #FFEEBA;

$responsesaved-icon-color: $panviva-teal;
$responsesaved-details-value-color: $panviva-navy;

$response-variation-box-border-color: $panviva-border-gray;
$response-variation-content-bg-color: rgba(64,188,161,0.25);
$channel-selector-color: #6c757d;
$selected-channel-bg-color: #E1ECF4;
$selected-channel-text-color: $panviva-navy;

$keyword-button-bg-color: #28A745;

$skeleton-table-row-color: rgba(72,80,86,0.15);

$published-badge-bg-color: #6c757d;
$draft-badge-bg-color: #17a2b8;
$status-badge-font-weight: 500;
$status-badge-font-size: 14px;

$header-bg-color: #F4F4F4;
$footer-text-color: $table-text-color;

$logout-secondary-color: $table-text-color;
$search-title-color: $table-text-color;
