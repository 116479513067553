@import "../../../../styles/variables";

.response-saved {
  i {
    font-size: 50px;
    color: $responsesaved-icon-color;
  }

  .details {
    padding-left: 141px;
    padding-right: 141px;
    align-items: flex-start;
    width: 100%;

    .key {
      margin-top: 15px;
      margin-bottom: 18px;
      font-size: 20px;
      line-height: 24px;
      color: $responsesaved-details-value-color;
      font-weight: 500;
    }

    .value {
      margin-top: 0px;
      margin-bottom: 15px;
      line-height: 21px;
      color: black;
    }
  }

  .ok-btn {
    margin-top: 15px;
    justify-content: flex-end;

    .btn {
      width: 250px;
    }
  }
}
