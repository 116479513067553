%table {
  //margin-top: 10px;
  width: 100%;
  display: table;

  &:not(.table-hover) {
    tbody {
      tr {
        cursor: default;
      }
    }
  }

  thead {
    border-bottom: .5px solid $border-color;

    th {
      font-size: 14px;
      border-top: none;
      border-bottom: none;
      padding-left: 10px;
      color: $table-row-header-color;
      font-weight: 500;

      &.referenceId {
        width: 90px;
        min-width: 90px;

      }

      &.date {
        width: 200px;
        min-width: 200px;

        .date-label {
          display: inline-block;
          width: 70%;
        }

        .fa, .icon {
          cursor: pointer;
          margin-left: 10px;
        }
      }

      &.category {
        width: 150px;
        min-width: 150px;
      }
    }
  }

  tbody {
    color: $table-text-color;
    border-bottom: .5px solid $border-color;

    tr {
      &:hover {
        //text-shadow: 1px 0 0 $table-row-hover-color;
        //color: $table-row-hover-color;
        background-color: $pv-row-hover;
        cursor: pointer;
      }

      td {
        border: none;
        padding-left: 10px;
        
        

        .badge {
          font-size: $status-badge-font-size;
          font-weight: $status-badge-font-weight;
          margin-top: 0px;
        }

        .badge-primary {
          background-color: $published-badge-bg-color;
        }

        .badge-secondary {
          background-color: $draft-badge-bg-color;
        }
      }
    }
  }
}
