@import '../../../styles/variables';

.analytics-container {
    
    width: 96%;

    .analytics-title{
        padding: 20px;
        border-bottom: 1px solid $pv-border-colour;
        background: $pv-light-colour;
        font-size: 18px;
        font-weight: 600;
        color: #265173;
    }
    .analytics-page {
        background-color: white;
        margin-left: 1%;
        padding: 0.5%;
        border-radius: 1%;
        }
}