@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import '../../styles/_settings';

.dashboard {
  padding: 0;
  background: $pv-background-sub-colour;
  margin-top: -25px;
    
  h1.title{
    padding: 20px;
    border-bottom: 1px solid $pv-border-colour;
    background: $pv-light-colour;
  }
  .message {
    margin-bottom: 25px;
    font-weight: normal;
    font-size: 14px;
  }
  .search-bar{
    background-color: $pv-light-colour;
    padding: 20px;
    margin-top: 145px;
    margin-left: 20px;
    padding-bottom: 2px;
    width: calc(100% - 40px);
  }
  .drafts-responses{
    background-color: $pv-light-colour;
    margin-left: 20px;
    padding-top: 0;
    width: calc(100% - 40px);
  }
  @include media-breakpoint-down(sm) {
    padding: 20px;
  }
}
