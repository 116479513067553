@import '../../../styles/variables';

.card-page {
    width: 100%;
    margin-left: 58px;
    margin-right: 72px;
    margin-top: 27px;

    h1 {
        font-size: 30px;
        font-weight: 500;
        line-height: 35px;
    }

    .card-page-card {
        width: 100%;
        margin-top: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;

        box-sizing: border-box;
        border: 1px solid #979797;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14), 0 3px 4px 0 rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);


        padding-top: 30px;
        padding-bottom: 20px;
    }
}