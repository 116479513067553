@import "../../../../styles/variables.scss";
.response-variations {
  width: 100%;

  .add-new-variation {
    display: flex;
    width: 100%;
    
    .btn-outline-secondary {
      font-weight: 500;
      background-color: $pv-light-colour;
      color: $pv-primary-colour;
      border: 1px solid $pv-primary-colour;

      &:disabled {
        background-color: $btn-disabled-bg-color;
      }

      .fa, .icon {
        margin-right: 10px;
      }
    }
  }
}
