.input-group.search-group {
    width: 60%;
    margin-top: 30px;
    margin-bottom: 10px;
    .input-group-append {
        .btn {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            font-size: 14px;
        }
    }

    .form-control {
        font-size: 14px;
        &:focus {
          box-shadow: 0 0 0 0.05rem rgba(0, 123, 255, 0.25);
        }
    }

    .is-invalid {
        border-color: $required-flag-color;
    }

    .invalid-feedback {
        font-size: 14px;
        &.visible {
            display: block;
        }
    }

    .no-display {
        display: none !important;
    }
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid, .custom-select.is-valid {
    border-color: inherit;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: inherit;
    box-shadow: inherit;
}