@import "../../../styles/variables";

.manage-responses {
  width: 100%;

  .page-title {
    padding-top: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    &.manage-draft {
      color: $pv-text-colour-80;
      background-color: $pv-light-colour;
      padding: 0 20px;
      position: relative;
      height: 55px;
      line-height: 55px;
      vertical-align: middle;
      border-bottom: 1px solid $pv-border-colour;

      h1 {
        font-size: 18px;
      }
    }

    h1 {
      font-size: 30px;
      font-weight: 500;
      line-height: 35px;
    }

    .required-fields {
      font-size: 14px;
      font-weight: 500;

      &:before {
        content: "*";
        color: $required-flag-color;
        margin-right: 2px;
        font-size: 14px;
        font-weight: 500;
        position: relative;
        top: -0.3em;
      }
    }

    border-bottom: 2px solid $border-color;
  }

  .response-form {
    // width: 100%;
    background-color: $pv-light-colour;
    padding: 16px;
    margin-right: 20px;
    margin-left: 20px;

    h5 {
      font-size: 14px;
    }

    .form-group {
      min-height: 60px;
      margin-bottom: 10px;

      &.required {
        h5 {
          &:after {
            content: "*";
            color: $required-flag-color;
            margin-left: 2px;
            font-size: 14px;
            font-weight: 500;
            position: relative;
            top: -0.3em;
          }
        }
      }

      .form-control {
        color: $manage-form-input-color;
        border: 1px solid $panviva-border-gray;
      }

      .form-select {
        size: 100px;
      }

      .field-label {
        display: flex;
        flex-direction: row;

        .fa, .icon {
          margin-left: 5px;
        }
      }

      .input-container-with-badge {
        position: relative;

        .badge {
          position: absolute;
          font-weight: $status-badge-font-weight;
          top: 5px;
          left: 10px;
          font-size: $status-badge-font-size;
          line-height: 17px;
          background-color: $pv-published-response;
          color: $pv-text-inside-colour;
          text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        }

        .form-control {
          text-indent: 50px;
        }
      }

      .invalid-feedback {
        padding-left: 10px;
      }
    }

    .accordion {
      border: none;

      .card {
        border: none;
        overflow: inherit;

        .card-header {
          padding-left: 0;
          padding-bottom: 0;
          background-color: $pv-blue10;
          padding-left: 10px;
          border: 1px solid rgba(0, 0, 0, 0.125);
          border-radius: 0.25rem;
          margin-bottom: 0px;

          h5 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .header-text {
              display: inline-flex;
              flex-direction: row;
              font-size: 16px;
              padding-bottom: 8px;

              .fa, .icon {
                margin-left: 5px;

                &:hover {
                  cursor: pointer !important;
                }
              }

              .wand-icon-blue {
                border: 2px solid #2574DB;
                border-radius: 4px;
                width: 29px;
                height: 29px;
                margin-top: -4px;
                margin-left: 5px;
              }

              .wand-icon-disabled {
                border: 2px solid #6B7786;
                border-radius: 4px;
                width: 29px;
                height: 29px;
                margin-top: -4px;
                margin-left: 5px;
              }

              .margin-8{
                  margin: -8px;
              }
            }
          }
        }

        .card-body {
          padding: 18px 0 0 0;

          .query-variations {

            .input-group {
              margin-bottom: 12px;

              &:not(.last) {
                .form-control {
                  border-right: none;
                }
              }

              .input-group-text {
                background-color: white;
                border: none;
              }

              .btn-outline-secondary {
                font-weight: 500;
                background-color: $pv-light-colour;
                color: $pv-primary-colour;
                border: 1px solid $pv-primary-colour;

                &:disabled {
                  background-color: $btn-disabled-bg-color;
                }

                .fa, .icon {
                  margin-right: 10px;
                }
              }
            }

            .top-bottom-border {
              border-top: 1px solid #ced4da;
              border-bottom: 1px solid #ced4da;
            }

            .top-bottom-right-border{
              border-top: 1px solid #ced4da;
              border-bottom: 1px solid #ced4da;
              border-right: 1px solid #ced4da;
            }
          }
        }
      }
    }

    .response-details {
      width: 100%;
      padding: 40px 0 0 0;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      .response-details-left {
        width: 100%;
      }

      .response-details-right {
        width: 100%;
      }
    }

    @include media-breakpoint-up(xl) {
      // Styles for width above 1200px
      .response-details {
        .response-details-left {
          //width: 50%;
          padding-right: 20px;
        }

        .response-details-right {
          width: 50%;
          padding-left: 20px;
        }
      }
    }


    .form-fields {

      &.half {
        width: 60%;
        margin-top: 25px;
      }

      &.title {
        display: flex;
        flex-direction: column;
        width: 100%;

        .doc-id {
          display: flex;
          flex-direction: row;
          margin-bottom: 20px;
          font-size: 14px;
          color: $manage-doc-info-color;

          span {
            margin-left: 10px;
          }

          .doc-number {
            width: 20%;
            min-width: 180px;
          }
        }
      }
    }

    .manage-footer {
      width: 100%;
      margin-top: 22px;
      border-top: 2px solid $border-color;
      padding: 17px 20px 12px 44px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .btn {
        margin-left: 15px;
        //font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        //flex: 0 1 174px;
        //min-width: 200px;
        text-align: center;
      }
    }
  }

  .msg-tooltip-icon {
    i {
      font-size: 14px;
    }
  }

  .manage-response-notifications {
    margin: 10px 0px 0px 0px;
    border: 1px solid $notification-border-color;
    border-radius: 4px;
    background-color: $notification-bg-color;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    height: 2.5em;
    align-items: center;
    justify-content: space-between;

    i.fa, i.icon {
      float: left;
      color: black;
      width: 16px;
      font-size: 20px;
      margin: 15px 10px;
      text-align: center;
    }

    .notification-button-container {
      display: flex;
      padding-right: 1ex;

      .notification-button {
        color: white;
        border: 0.1em solid black;
        border-radius: 4px;
      }
    }

    .notification-text {
      display: flex;
      margin: 15px 10px;
      text-align: center;
    }
  }
}
