@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import '../../../styles/_settings';

main {
    &.logout-main {
        background-color: $logout-secondary-color;
    }

    .logout-container {
        background-color: white;
        box-shadow: 4px 4px 6px 0 rgba(0,0,0,0.25);
        border-radius: 4px;
        width: 487px;
        margin: 20% auto;
        padding: 43px 60px 43px 60px;

        @include media-breakpoint-down(sm) {
            margin: 10px;
            width: auto;
            padding: 30px;
        }

        .title {
            color: $text-color;
            font-weight:normal;
            padding-top: 0;
            margin-top: 0;
        }

        .message {
            color: $logout-secondary-color;
            line-height: 25px;
            margin-bottom: 38px;
        }
    }
}