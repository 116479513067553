@import '../../../styles/_settings';
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.pagination-container {
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-right: 17px;
    
    @include media-breakpoint-down(sm) {
        display: block;
    }

    .page-item {
        font-size: 14px;
        .page-link {
            color: $pv-icon-colour;
            background-color: $pv-light-colour;
            border: none;
         }
         &.active {
            .page-link {
                background-color: $pv-light-colour;
                color: $pv-primary-colour;
                border: none;
                cursor: default;
                pointer-events: none;
            }
        }
    }

    .page-size {
        margin-left: 10px;
        display: inline-flex;
        flex-direction: row;
        .form-control {
            max-width: 80px;
            font-size: 14px;
            height: 35px;
            appearance: auto;
        }
        .item-per-page {
            min-width: 85px;
            margin-left: 10px;
            color: $panviva-navy;
            font-size: 12px;
            padding-top: 8px;
        }
    }
}