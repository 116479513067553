$icomoon-font-family: "pv-fonts" !default;
$icomoon-font-path: "/icons" !default;

$angle-right-back: "\e921";
$angle-right-next: "\e92b";
$angle-left: "\f104";
$angle-right: "\f105";
$angle-up: "\f106";
$angle-down: "\f107";
$add-circle-outline: "\e919";
$icon-add_circle: "\e919";
$components-icon-trans-incomplete-filled: "\e9c1";
$icon-translation-incomplete: "\e9c1";
$components-icon-trans-complete-filled: "\e9c3";
$icon-translation-complete: "\e9c3";
$icon-folder: "\e9c2";
$icon-folder-opened: "\e9c2";
$icon-folder-closed: "\e9c2";
$components-icon-doctype-process: "\e9bb";
$icon-process: "\e9bb";
$components-icon-upland-panviva-logo-path1: "\e9b1";
$components-icon-upland-panviva-logo-path2: "\e9b3";
$components-icon-upland-panviva-logo-path3: "\e9b4";
$components-icon-upland-panviva-logo-path4: "\e9b5";
$components-icon-upland-panviva-logo-path5: "\e9b6";
$components-icon-upland-panviva-logo-path6: "\e9b7";
$components-icon-upland-panviva-logo-path7: "\e9b8";
$components-icon-upland-panviva-logo-path8: "\e9b9";
$components-icon-panviva-logo-text: "\e9ba";
$components-icon-upland-logo: "\e9b2";
$components-icon-task-shortcut: "\e954";
$icon-task-shortcut: "\e954";
$components-icon-archive: "\e91e";
$icon-archive: "\e91e";
$components-icon-text: "\e997";
$icon-text: "\e997";
$components-icon-merge: "\e9ac";
$icon-merge: "\e9ac";
$components-icon-tool-quicklinks-grid: "\e96e";
$icon-quicklinks-grid: "\e96e";
$components-icon-import-user: "\e90c";
$icon-import-user: "\e90c";
$components-icon-export-user: "\e995";
$icon-export-user: "\e995";
$components-icon-custom-role: "\e998";
$icon-custom-role: "\e998";
$components-icon-right-to-left: "\e999";
$icon-right-to-left: "\e999";
$components-icon-version: "\e99a";
$icon-version: "\e99a";
$components-icon-taskflow-diagram: "\e99b";
$icon-taskflow-diagram: "\e99b";
$components-icon-clear-search: "\e99c";
$icon-search-clear: "\e99c";
$components-icon-scissor: "\e99d";
$components-icon-flag: "\e99e";
$components-icon-system-role: "\e99f";
$icon-system-role: "\e99f";
$components-icon-check-circle: "\e9a0";
$icon-checkmark-circle: "\e9a0";
$components-icon-check-outline: "\e9a1";
$icon-checkmark: "\e9a1";
$components-icon-released: "\e9a2";
$icon-out: "\e9a2";
$components-icon-split: "\e9a3";
$icon-cut: "\e9a3";
$components-icon-key: "\e9a4";
$icon-key2: "\e9a4";
$components-icon-clock: "\e9a5";
$icon-clock: "\e9a5";
$components-icon-calendar: "\e9a6";
$icon-calendar: "\e9a6";
$components-icon-thumbs-down: "\e9a7";
$icon-thumbs-down: "\e9a7";
$components-icon-thumbs-up: "\e9a8";
$icon-thumbs-up: "\e9a8";
$components-icon-expand: "\e9a9";
$components-icon-zoom-in: "\e9aa";
$icon-zoom: "\e9aa";
$components-icon-zoom-out: "\e9ab";
$icon-zoom-out: "\e9ab";
$components-icon-preview: "\e943";
$icon-eye: "\e943";
$components-icon-add: "\e900";
$icon-add: "\e900";
$components-icon-arrow-default-arrow-back: "\e901";
$icon-arrow-left: "\e901";
$components-icon-arrow-default-arrow-next: "\e902";
$icon-arrow-right: "\e902";
$components-icon-caret-down-thin: "\e903";
$icon-caret-down: "\e903";
$components-icon-caret-left-thin: "\e904";
$icon-caret-left: "\e904";
$components-icon-caret-right-thin: "\e905";
$icon-caret-right: "\e905";
$components-icon-caret-up-thin: "\e906";
$icon-caret-up: "\e906";
$components-icon-check-thin: "\e907";
$components-icon-chevron-down: "\e908";
$icon-chevron-down: "\e908";
$components-icon-chevron-left: "\e909";
$icon-chevron-left: "\e909";
$components-icon-chevron-right: "\e90a";
$icon-chevron-right: "\e90a";
$components-icon-chevron-up: "\e90b";
$icon-chevron-up: "\e90b";
$components-icon-close: "\e90d";
$components-icon-close-thin: "\e90e";
$icon-close: "\e90e";
$components-icon-cog: "\e90f";
$icon-cog: "\e90f";
$components-icon-collapse-diagonal: "\e910";
$components-icon-collapse-horizontal: "\e911";
$components-icon-compress-diagonal: "\e913";
$components-icon-copy: "\e914";
$icon-copy: "\e914";
$components-icon-csh: "\e915";
$icon-csh: "\e915";
$components-icon-current-document-2: "\e916";
$components-icon-current-document: "\e917";
$icon-current-document: "\e917";
$components-icon-delete: "\e918";
$icon-remove-trash: "\e918";
$components-icon-details: "\e91a";
$icon-profile-2: "\e91a";
$components-icon-doctype-external: "\e91b";
$icon-external: "\e91b";
$components-icon-doctype-policy: "\e91c";
$icon-policy: "\e91c";
$components-icon-doctype-procedure: "\e91d";
$icon-procedure: "\e91d";
$components-icon-tool-reusable-content: "\e91f";
$components-icon-doctype-rc: "\e91f";
$icon-reusable-content: "\e91f";
$icon-rc: "\e91f";
$icon-document: "\e91f";
$components-icon-doctype-workflow: "\e920";
$icon-workflow: "\e920";
$components-icon-download: "\e922";
$icon-download: "\e922";
$components-icon-edit: "\e923";
$icon-edit: "\e923";
$icon-pencil: "\e923";
$components-icon-expand-diagonal: "\e924";
$icon-expand: "\e924";
$components-icon-expand-horizontal: "\e925";
$icon-expand-horizontal: "\e925";
$components-icon-favourites: "\e926";
$icon-favourites: "\e926";
$components-icon-filter: "\e927";
$icon-filter: "\e927";
$components-icon-find: "\e928";
$components-icon-find-replace: "\e929";
$icon-search-binoculars: "\e929";
$components-icon-find-within: "\e92a";
$icon-findWithin: "\e92a";
$components-icon-history: "\e92c";
$icon-history: "\e92c";
$icon-nav-history: "\e92c";
$components-icon-home: "\e92d";
$icon-home: "\e92d";
$components-icon-info: "\e92e";
$icon-info: "\e92e";
$icon-info-circle: "\e92e";
$components-icon-jump: "\e92f";
$icon-jump: "\e92f";
$components-icon-legend: "\e930";
$icon-legend: "\e930";
$components-icon-list: "\e931";
$icon-list: "\e931";
$components-icon-location-on: "\e932";
$icon-location: "\e932";
$components-icon-mail: "\e933";
$icon-email-2: "\e933";
$components-icon-menu-menu-default: "\e934";
$icon-menu: "\e934";
$components-icon-meter: "\e935";
$icon-meter: "\e935";
$components-icon-more_vert: "\e936";
$components-icon-multi-view: "\e937";
$icon-detach-window: "\e937";
$components-icon-no-read-write: "\e938";
$components-icon-note: "\e939";
$icon-note: "\e939";
$components-icon-notifications: "\e93a";
$icon-notifications: "\e93a";
$components-icon-no-write: "\e93b";
$components-icon-online-learning: "\e93c";
$icon-online-learning: "\e93c";
$icon-online-training: "\e93c";
$components-icon-open: "\e93d";
$icon-open: "\e93d";
$components-icon-panviva: "\e93e";
$icon-panviva-logo: "\e93e";
$components-icon-power: "\e942";
$icon-power: "\e942";
$components-icon-previous-document: "\e944";
$icon-previous-document: "\e944";
$components-icon-print: "\e945";
$icon-print: "\e945";
$components-icon-properties: "\e946";
$icon-properties: "\e946";
$components-icon-redo: "\e947";
$icon-redo: "\e947";
$components-icon-reference: "\e948";
$icon-reference: "\e948";
$components-icon-refresh: "\e949";
$icon-refresh: "\e949";
$components-icon-report: "\e94a";
$icon-report: "\e94a";
$components-icon-restricted: "\e94b";
$icon-restricted: "\e94b";
$components-icon-save: "\e94d";
$icon-save: "\e94d";
$components-icon-search: "\e94e";
$icon-search-magnifying: "\e94e";
$components-icon-spell-check: "\e94f";
$icon-spell-check: "\e94f";
$components-icon-star: "\e950";
$icon-star: "\e950";
$icon-rating: "\e950";
$components-icon-star-fill: "\e951";
$icon-star-fill: "\e951";
$components-icon-task-path: "\e952";
$icon-task-path: "\e952";
$components-icon-task-screen: "\e953";
$icon-task-type-screen: "\e953";
$components-icon-task-transaction: "\e955";
$icon-task-transaction: "\e955";
$components-icon-tool-anchor: "\e957";
$icon-anchor: "\e957";
$components-tool-bold: "\e958";
$icon-bold: "\e958";
$components-tool-buttonise: "\e959";
$icon-buttonise: "\e959";
$components-icon-tool-character: "\e95a";
$icon-character: "\e95a";
$components-icon-tool-header: "\e95c";
$icon-header: "\e95c";
$components-icon-tool-image: "\e95d";
$icon-image: "\e95d";
$components-icon-tool-imagemap: "\e95e";
$icon-imagemap: "\e95e";
$components-icon-tool-indent-decrease: "\e95f";
$icon-indent-decrease: "\e95f";
$components-icon-tool-indent-increase: "\e960";
$icon-indent-increase: "\e960";
$components-icon-tool-italic: "\e961";
$icon-italic: "\e961";
$components-icon-tool-link: "\e962";
$icon-link: "\e962";
$components-icon-tool-multiple-choice-radio: "\e963";
$icon-multiple-choice-radio: "\e963";
$components-icon-tool-ordered-list: "\e964";
$icon-ordered-list: "\e964";
$components-icon-tool-ordered-list-change: "\e965";
$icon-ordered-list-change: "\e965";
$components-icon-tool-ordered-list-continue: "\e966";
$icon-ordered-list-continue: "\e966";
$components-icon-tool-ordered-list-restart: "\e967";
$icon-ordered-list-restart: "\e967";
$components-icon-tool-paragraph: "\e968";
$icon-paragraph: "\e968";
$components-icon-tool-paragraph-centered: "\e969";
$icon-paragraph-centered: "\e969";
$components-icon-tool-paragraph-insert-after: "\e96a";
$icon-paragraph-insert-after: "\e96a";
$components-icon-tool-paragraph-insert-before: "\e96b";
$icon-paragraph-insert-before: "\e96b";
$components-icon-tool-paragraph-left: "\e96c";
$icon-paragraph-left: "\e96c";
$components-icon-tool-paragraph-right: "\e96d";
$icon-paragraph-right: "\e96d";
$components-icon-tool-secondary-window: "\e970";
$icon-secondary-window: "\e970";
$components-icon-tool-section: "\e971";
$icon-section: "\e971";
$components-icon-tool-section-insert-after: "\e972";
$icon-section-insert-after2: "\e972";
$components-icon-tool-section-insert-before: "\e973";
$icon-section-insert-before2: "\e973";
$components-icon-tool-stock-text: "\e974";
$icon-stock-text: "\e974";
$components-icon-tool-subheader: "\e975";
$icon-subheader: "\e975";
$components-icon-tool-sub-unordered-list: "\e976";
$icon-sub-unordered-list: "\e976";
$components-icon-tool-table: "\e977";
$icon-table: "\e977";
$components-icon-tool-table-add-column-left: "\e978";
$icon-table-add-column-left: "\e978";
$components-icon-tool-table-add-column-right: "\e979";
$icon-table-add-column-right: "\e979";
$components-icon-tool-table-add-row-above: "\e97a";
$icon-table-add-row-above: "\e97a";
$components-icon-tool-table-add-row-below: "\e97b";
$icon-table-add-row-below: "\e97b";
$components-icon-tool-table-cell-merge-right: "\e97c";
$icon-table-cell-merge-right: "\e97c";
$components-icon-tool-table-cell-split: "\e97d";
$icon-table-cell-split: "\e97d";
$components-icon-tool-table-column-delete: "\e97e";
$icon-table-column-delete: "\e97e";
$components-icon-tool-table-header-hide: "\e97f";
$icon-table-header-hide: "\e97f";
$components-icon-tool-table-header-show: "\e980";
$icon-table-header-show: "\e980";
$components-icon-tool-table-row-delete: "\e981";
$icon-table-row-delete: "\e981";
$components-icon-tool-table-set-width-large: "\e982";
$icon-table-set-width-large: "\e982";
$components-icon-tool-table-set-width-medium: "\e983";
$icon-table-set-width-medium: "\e983";
$components-icon-tool-table-set-width-small: "\e984";
$icon-table-set-width-small: "\e984";
$components-icon-tool-task: "\e985";
$icon-task: "\e985";
$components-icon-tool-task-insert-after: "\e986";
$icon-task-insert-after: "\e986";
$components-icon-tool-task-insert-before: "\e987";
$icon-task-insert-before: "\e987";
$components-icon-tool-tooltip: "\e988";
$icon-popup: "\e988";
$components-icon-tool-training-answer: "\e989";
$icon-training-answer: "\e989";
$components-icon-tool-training-question: "\e98a";
$icon-training-question: "\e98a";
$components-icon-tool-unlink: "\e98b";
$icon-unlink: "\e98b";
$components-icon-tool-unordered-list: "\e98c";
$icon-unordered-list: "\e98c";
$components-icon-tool-verbatim: "\e98d";
$icon-verbatim: "\e98d";
$components-icon-trans-complete: "\e98e";
$components-icon-trans-incomplete: "\e98f";
$components-icon-unarchive: "\e990";
$icon-unarchive: "\e990";
$components-icon-undo: "\e991";
$icon-undo: "\e991";
$components-icon-upload: "\e992";
$icon-upload: "\e992";
$components-icon-user: "\e993";
$icon-user2: "\e993";
$components-icon-task-who: "\e993";
$icon-user: "\e993";
$components-icon-warning: "\e994";
$icon-warning: "\e994";
$components-icon-no-write-filled: "\e9bd";
$icon-unwriteable: "\e9bd";
$components-icon-no-read-write-filled: "\e9be";
$icon-unreadable: "\e9be";
$components-icon-document-default: "\e9c0";
$components-icon-tool-document: "\e9c0";
$components-icon-comments: "\e912";
$icon-comments: "\e912";
$components-icon-announcements: "\e9ad";
$icon-megaphone: "\e9ad";
$icon-arrow-up: "\2191";
$icon-arrow-down: "\2193";
