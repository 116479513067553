@import '../../../../styles/variables';

.search-response {
  list-style-type: none;
  position: relative;
  padding: 5px;
  padding-top: 0;

  .header{
    color: $pv-text-colour-80;
    font-size: 16px;
    font-weight: 600;
  }
  .description{
    color: $pv-text-colour-80;
    font-size: 14px;
  }
  .form-inline {
    align-items: flex-start;
    padding-left: 20px;
    padding-bottom: 10px;
  }



  li.info {
    margin: 20px 0;
    padding: 19px 0;
    font-size: 14px;
    background-color: $pv-light-colour;

    &:not(.not-timeline) {
      &:before {
        content: attr(data-value);
        vertical-align: middle;
        color: $timeline-item-font-color;
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        display: inline-block;
        position: relative;
        border-radius: 50%;
        z-index: 400;
        height: 32px;
        line-height: 32px;
        width: 32px;
        left: 0;
        background-color: $timeline-item-bg;
        float: left;
        margin: 7px 12px 0 20px;
      }
    }


    .form-group {


      .col-sm-4 {
        padding-right: 10px;
      }
    }

    .new-msg {
      border: 1px solid $note-box-border;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14), 0 3px 4px 0 rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
      padding: 20px 20px;
      text-align: center;
      min-width: 500px;
      width: 35%;

      @include media-breakpoint-down(sm) {
        padding: 40px;
      }

      .create-new-btn {
        font-size: 14px;
        padding: 12px 21px 12px 21px;
      }

      .msg {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 35px;

        .msg-tooltip-icon {
          display: inline;
          padding-left: 10px;
        }

        .cc-tooltip {
          line-height: 18px;
          font-size: 14px;
        }
      }
    }
  }

  .document-deleted-notification {
    height: 46px;
    margin: 10px 0px 0px 0px;
    border: 1px solid $notification-border-color;
    border-radius: 4px;
    background-color: $notification-bg-color;
    font-size: 14px;
    line-height: 18px;

    i.fa, i.icon {
      float: left;
      display:block;
      width: 16px;
      font-size: 14px;
      margin: 15px 11px;
      text-align: center;
    }

    .notification-text {
      padding: 5px 0px 3px 0px;
    }
  }

  .composite-response-list {
    padding-right: 0px;
  }

  .information-circle{
    font-size: 15pt;
    padding-left: 4px;
  }
  .new-response-btn{
    margin-top: 20px;
    margin-left: 60px;
  }

  .no-right-border-radius {
    border-bottom-right-radius: unset;
    border-top-right-radius: unset;
  }

  .no-left-border-radius {
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
  }

  .margin-left-20 {
    margin-left: 20px;
  }

  .margin-top-10 {
    margin-top: 10px;
  }

  .disable-button {
    background-color: #E4E5E6 !important;
    color: #485056 !important;
    border: none;
  }
}
