@import '../../../../styles/_settings';

.document-step-panel {
    height: 100%;
    color: $steppanel-color;
    display: flex;
    flex-flow: column;
    p {
        position: absolute;
        margin-left: 32px;
        margin-top: 15px;
    }
    .section-selector-container{
        p {
            position: inherit;
            color: $pv-text-colour-80;
        }
    }
    p {
        padding: 10px 25px;
        font-size: 14px;
        margin-bottom: 0px;
    }

    h4 {
        color: $steppanel-heading-color;
        margin-bottom: 0;
        font-size: 24px;
        margin-left: 54px;
        .star {
            font-weight: 500;
            top: -.3em;
            left: -.2em;
        }

        .step-number {
            display: inline-block;
            height: 46px;
            width: 46px;
            line-height: 46px;
            border-radius: 4px;
            font-size: 24px;
            text-align: center;
            color: $pv-light-colour;
            background-color: $steppanel-heading-color;
            vertical-align: middle;
            margin-top: -2px;
            position: absolute;
            margin-left: -54px;
        }
    }
}