@import '../../styles/settings';

$width: 100px;

.showbox {
  display: none;

  &:first-of-type {
    display: block;
  }

  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, 0.7);
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5%;
  z-index: 1000;
  pointer-events: prevent-default;

  .loader {
    position: relative;
    margin: 0 auto;
    margin-top: 15%;
    width: $width;

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }

  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  h1 {
    text-align: center;
    vertical-align: middle;
    align-self: center;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  0% {
    stroke: $panviva-teal;
  }
  20% {
    stroke: $panviva-navy;
  }
  40% {
    stroke: $panviva-teal;
  }
  60% {
    stroke: $panviva-navy;
  }
  80% {
    stroke: $panviva-teal;
  }
  100% {
    stroke: $panviva-navy;
  }
}
