@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import '../../../../styles/_settings';

.active {
    .home-tile {
        border-left: 8px solid $pv-primary-colour;
        background-color: rgba(255, 255, 255, 1);
    }
}

.home-tile {
    width: 267px;
    height: 104px;
    background-color: $pv-light-colour;
    color: $pv-dark-colour;
    box-shadow: 0 1.2px 2px rgba(0, 0, 0, 0.39);
    text-align: left;
    margin-right: 20px;
    display: inline-block;
    padding-top: 15px;
    text-transform: uppercase;
    padding-left: 22px;
    border-radius: 4px;

    .heading {
        height: 19px;
        font-weight: 500;
        line-height: 19px;
        margin: 0;
        padding: 0;
        color: $pv-icon-colour;
    }
    span {
        display: block;
        &.count {
            height: 42px;
            font-size: 36px;
            font-weight: 500;
            line-height: 42px;
            margin: 5px 0;
        }
        &.subtitle {
            height: 11px;
            font-size: 10px;
            line-height: 11px;
        }
    }

    @include media-breakpoint-down(sm) {
        display: block;
        padding: 12px;
        margin: 0 0 12px 0;
        width: auto;
        height: auto;
    }
}