@import "_font_variables";

@font-face {
  font-family: $icomoon-font-family;
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?fsm4yu")
      format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?fsm4yu")
      format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?fsm4yu##{$icomoon-font-family}")
      format("svg");

  // url('~/icons/pv-fonts.ttf?fsm4yu') format('truetype'),
  // url('~/icons/pv-fonts.woff?fsm4yu') format('woff'),
  // url('~/icons/pv-fonts.svg?fsm4yu#pv-fonts') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $icomoon-font-family !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:hover {
    cursor: pointer !important;
  }
}

.angle-right-back {
  &:before {
    content: $angle-right-back;
  }
}
.angle-right-next {
  &:before {
    content: $angle-right-next;
  }
}
.angle-left {
  &:before {
    content: $angle-left;
  }
}
.angle-right {
  &:before {
    content: $angle-right;
  }
}
.angle-up {
  &:before {
    content: $angle-up;
  }
}
.angle-down {
  &:before {
    content: $angle-down;
  }
}
.add-circle-outline {
  &:before {
    content: $add-circle-outline;
  }
}
.icon-add_circle {
  &:before {
    content: $icon-add_circle;
  }
}
.components-icon-trans-incomplete-filled {
  &:before {
    content: $components-icon-trans-incomplete-filled;
    color: #e60c51;
  }
}
.icon-translation-incomplete {
  &:before {
    content: $icon-translation-incomplete;
    color: #e60c51;
  }
}
.components-icon-trans-complete-filled {
  &:before {
    content: $components-icon-trans-complete-filled;
    color: #76ca02;
  }
}
.icon-translation-complete {
  &:before {
    content: $icon-translation-complete;
    color: #76ca02;
  }
}
.icon-folder {
  &:before {
    content: $icon-folder;
  }
}
.icon-folder-opened {
  &:before {
    content: $icon-folder-opened;
  }
}
.icon-folder-closed {
  &:before {
    content: $icon-folder-closed;
  }
}
.components-icon-doctype-process {
  &:before {
    content: $components-icon-doctype-process;
  }
}
.icon-process {
  &:before {
    content: $icon-process;
  }
}
.components-icon-upland-panviva-logo .path1 {
  &:before {
    content: $components-icon-upland-panviva-logo-path1;
    color: rgb(36, 31, 32);
  }
}
.components-icon-upland-panviva-logo .path2 {
  &:before {
    content: $components-icon-upland-panviva-logo-path2;
    margin-left: -4.3896484375em;
    color: rgb(36, 31, 32);
  }
}
.components-icon-upland-panviva-logo .path3 {
  &:before {
    content: $components-icon-upland-panviva-logo-path3;
    margin-left: -4.3896484375em;
    color: rgb(36, 31, 32);
  }
}
.components-icon-upland-panviva-logo .path4 {
  &:before {
    content: $components-icon-upland-panviva-logo-path4;
    margin-left: -4.3896484375em;
    color: rgb(36, 31, 32);
  }
}
.components-icon-upland-panviva-logo .path5 {
  &:before {
    content: $components-icon-upland-panviva-logo-path5;
    margin-left: -4.3896484375em;
    color: rgb(36, 31, 32);
  }
}
.components-icon-upland-panviva-logo .path6 {
  &:before {
    content: $components-icon-upland-panviva-logo-path6;
    margin-left: -4.3896484375em;
    color: rgb(36, 31, 32);
  }
}
.components-icon-upland-panviva-logo .path7 {
  &:before {
    content: $components-icon-upland-panviva-logo-path7;
    margin-left: -4.3896484375em;
    color: rgb(36, 31, 32);
  }
}
.components-icon-upland-panviva-logo .path8 {
  &:before {
    content: $components-icon-upland-panviva-logo-path8;
    margin-left: -4.3896484375em;
    color: rgb(37, 116, 219);
  }
}
.components-icon-panviva-logo-text {
  &:before {
    content: $components-icon-panviva-logo-text;
  }
}
.components-icon-upland-logo {
  &:before {
    content: $components-icon-upland-logo;
    color: #2574db;
  }
}
.components-icon-task-shortcut {
  &:before {
    content: $components-icon-task-shortcut;
  }
}
.icon-task-shortcut {
  &:before {
    content: $icon-task-shortcut;
  }
}
.components-icon-archive {
  &:before {
    content: $components-icon-archive;
  }
}
.icon-archive {
  &:before {
    content: $icon-archive;
  }
}
.components-icon-text {
  &:before {
    content: $components-icon-text;
  }
}
.icon-text {
  &:before {
    content: $icon-text;
  }
}
.components-icon-merge {
  &:before {
    content: $components-icon-merge;
  }
}
.icon-merge {
  &:before {
    content: $icon-merge;
  }
}
.components-icon-tool-quicklinks-grid {
  &:before {
    content: $components-icon-tool-quicklinks-grid;
  }
}
.icon-quicklinks-grid {
  &:before {
    content: $icon-quicklinks-grid;
  }
}
.components-icon-import-user {
  &:before {
    content: $components-icon-import-user;
  }
}
.icon-import-user {
  &:before {
    content: $icon-import-user;
  }
}
.components-icon-export-user {
  &:before {
    content: $components-icon-export-user;
  }
}
.icon-export-user {
  &:before {
    content: $icon-export-user;
  }
}
.components-icon-custom-role {
  &:before {
    content: $components-icon-custom-role;
  }
}
.icon-custom-role {
  &:before {
    content: $icon-custom-role;
  }
}
.components-icon-right-to-left {
  &:before {
    content: $components-icon-right-to-left;
  }
}
.icon-right-to-left {
  &:before {
    content: $icon-right-to-left;
  }
}
.components-icon-version {
  &:before {
    content: $components-icon-version;
  }
}
.icon-version {
  &:before {
    content: $icon-version;
  }
  &:hover{
    text-decoration: underline;
  }
}
.components-icon-taskflow-diagram {
  &:before {
    content: $components-icon-taskflow-diagram;
  }
}
.icon-taskflow-diagram {
  &:before {
    content: $icon-taskflow-diagram;
  }
}
.components-icon-clear-search {
  &:before {
    content: $components-icon-clear-search;
  }
}
.icon-search-clear {
  &:before {
    content: $icon-search-clear;
  }
}
.components-icon-scissor {
  &:before {
    content: $components-icon-scissor;
  }
}
.components-icon-flag {
  &:before {
    content: $components-icon-flag;
  }
}
.components-icon-system-role {
  &:before {
    content: $components-icon-system-role;
  }
}
.icon-system-role {
  &:before {
    content: $icon-system-role;
  }
}
.components-icon-check-circle {
  &:before {
    content: $components-icon-check-circle;
  }
}
.icon-checkmark-circle {
  &:before {
    content: $icon-checkmark-circle;
  }
}
.components-icon-check-outline {
  &:before {
    content: $components-icon-check-outline;
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark;
  }
}
.components-icon-released {
  &:before {
    content: $components-icon-released;
  }
}
.icon-out {
  &:before {
    content: $icon-out;
  }
}
.components-icon-split {
  &:before {
    content: $components-icon-split;
  }
}
.icon-cut {
  &:before {
    content: $icon-cut;
  }
}
.components-icon-key {
  &:before {
    content: $components-icon-key;
  }
}
.icon-key2 {
  &:before {
    content: $icon-key2;
  }
}
.components-icon-clock {
  &:before {
    content: $components-icon-clock;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;
  }
}
.components-icon-calendar {
  &:before {
    content: $components-icon-calendar;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.components-icon-thumbs-down {
  &:before {
    content: $components-icon-thumbs-down;
  }
}
.icon-thumbs-down {
  &:before {
    content: $icon-thumbs-down;
  }
}
.components-icon-thumbs-up {
  &:before {
    content: $components-icon-thumbs-up;
  }
}
.icon-thumbs-up {
  &:before {
    content: $icon-thumbs-up;
  }
}
.components-icon-expand {
  &:before {
    content: $components-icon-expand;
  }
}
.components-icon-zoom-in {
  &:before {
    content: $components-icon-zoom-in;
  }
}
.icon-zoom {
  &:before {
    content: $icon-zoom;
  }
}
.components-icon-zoom-out {
  &:before {
    content: $components-icon-zoom-out;
  }
}
.icon-zoom-out {
  &:before {
    content: $icon-zoom-out;
  }
}
.components-icon-preview {
  &:before {
    content: $components-icon-preview;
  }
}
.icon-eye {
  &:before {
    content: $icon-eye;
  }
}
.components-icon-add {
  &:before {
    content: $components-icon-add;
  }
}
.icon-add {
  &:before {
    content: $icon-add;
  }
}
.components-icon-arrow-default-arrow-back {
  &:before {
    content: $components-icon-arrow-default-arrow-back;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.components-icon-arrow-default-arrow-next {
  &:before {
    content: $components-icon-arrow-default-arrow-next;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.components-icon-caret-down-thin {
  &:before {
    content: $components-icon-caret-down-thin;
  }
}
.icon-caret-down {
  &:before {
    content: $icon-caret-down;
  }
}
.components-icon-caret-left-thin {
  &:before {
    content: $components-icon-caret-left-thin;
  }
}
.icon-caret-left {
  &:before {
    content: $icon-caret-left;
  }
}
.components-icon-caret-right-thin {
  &:before {
    content: $components-icon-caret-right-thin;
  }
}
.icon-caret-right {
  &:before {
    content: $icon-caret-right;
  }
}
.components-icon-caret-up-thin {
  &:before {
    content: $components-icon-caret-up-thin;
  }
}
.icon-caret-up {
  &:before {
    content: $icon-caret-up;
  }
}
.components-icon-check-thin {
  &:before {
    content: $components-icon-check-thin;
  }
}
.components-icon-chevron-down {
  &:before {
    content: $components-icon-chevron-down;
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}
.components-icon-chevron-left {
  &:before {
    content: $components-icon-chevron-left;
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}
.components-icon-chevron-right {
  &:before {
    content: $components-icon-chevron-right;
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}
.components-icon-chevron-up {
  &:before {
    content: $components-icon-chevron-up;
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up;
  }
}
.components-icon-close {
  &:before {
    content: $components-icon-close;
  }
}
.components-icon-close-thin {
  &:before {
    content: $components-icon-close-thin;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.components-icon-cog {
  &:before {
    content: $components-icon-cog;
  }
}
.icon-cog {
  &:before {
    content: $icon-cog;
  }
}
.components-icon-collapse-diagonal {
  &:before {
    content: $components-icon-collapse-diagonal;
  }
}
.components-icon-collapse-horizontal {
  &:before {
    content: $components-icon-collapse-horizontal;
  }
}
.components-icon-compress-diagonal {
  &:before {
    content: $components-icon-compress-diagonal;
  }
}
.components-icon-copy {
  &:before {
    content: $components-icon-copy;
  }
}
.icon-copy {
  &:before {
    content: $icon-copy;
  }
}
.components-icon-csh {
  &:before {
    content: $components-icon-csh;
  }
}
.icon-csh {
  &:before {
    content: $icon-csh;
  }
}
.components-icon-current-document-2 {
  &:before {
    content: $components-icon-current-document-2;
  }
}
.components-icon-current-document {
  &:before {
    content: $components-icon-current-document;
  }
}
.icon-current-document {
  &:before {
    content: $icon-current-document;
  }
}
.components-icon-delete {
  &:before {
    content: $components-icon-delete;
  }
}
.icon-remove-trash {
  &:before {
    content: $icon-remove-trash;
  }
}
.components-icon-details {
  &:before {
    content: $components-icon-details;
  }
}
.icon-profile-2 {
  &:before {
    content: $icon-profile-2;
  }
}
.components-icon-doctype-external {
  &:before {
    content: $components-icon-doctype-external;
  }
}
.icon-external {
  &:before {
    content: $icon-external;
  }
}
.components-icon-doctype-policy {
  &:before {
    content: $components-icon-doctype-policy;
  }
}
.icon-policy {
  &:before {
    content: $icon-policy;
  }
}
.components-icon-doctype-procedure {
  &:before {
    content: $components-icon-doctype-procedure;
  }
}
.icon-procedure {
  &:before {
    content: $icon-procedure;
  }
}
.components-icon-tool-reusable-content {
  &:before {
    content: $components-icon-tool-reusable-content;
  }
}
.components-icon-doctype-rc {
  &:before {
    content: $components-icon-doctype-rc;
  }
}
.icon-reusable-content {
  &:before {
    content: $icon-reusable-content;
  }
}
.icon-rc {
  &:before {
    content: $icon-rc;
  }
}
.icon-document {
  &:before {
    content: $icon-document;
  }
}
.icon-analytics {
  &:before {
    content: url("/images/reportsicon.svg");
    margin-left: 5px;
  }
}
.components-icon-doctype-workflow {
  &:before {
    content: $components-icon-doctype-workflow;
  }
}
.icon-workflow {
  &:before {
    content: $icon-workflow;
  }
}
.components-icon-download {
  &:before {
    content: $components-icon-download;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.components-icon-edit {
  &:before {
    content: $components-icon-edit;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil;
  }
}
.components-icon-expand-diagonal {
  &:before {
    content: $components-icon-expand-diagonal;
  }
}
.icon-expand {
  &:before {
    content: $icon-expand;
  }
}
.components-icon-expand-horizontal {
  &:before {
    content: $components-icon-expand-horizontal;
  }
}
.icon-expand-horizontal {
  &:before {
    content: $icon-expand-horizontal;
  }
}
.components-icon-favourites {
  &:before {
    content: $components-icon-favourites;
  }
}
.icon-favourites {
  &:before {
    content: $icon-favourites;
  }
}
.components-icon-filter {
  &:before {
    content: $components-icon-filter;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.components-icon-find {
  &:before {
    content: $components-icon-find;
  }
}
.components-icon-find-replace {
  &:before {
    content: $components-icon-find-replace;
  }
}
.icon-search-binoculars {
  &:before {
    content: $icon-search-binoculars;
  }
}
.components-icon-find-within {
  &:before {
    content: $components-icon-find-within;
  }
}
.icon-findWithin {
  &:before {
    content: $icon-findWithin;
  }
}
.components-icon-history {
  &:before {
    content: $components-icon-history;
  }
}
.icon-history {
  &:before {
    content: $icon-history;
  }
}
.icon-nav-history {
  &:before {
    content: $icon-nav-history;
  }
}
.components-icon-home {
  &:before {
    content: $components-icon-home;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.components-icon-info {
  &:before {
    content: $components-icon-info;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-info-circle {
  &:before {
    content: $icon-info-circle;
  }
}
.components-icon-jump {
  &:before {
    content: $components-icon-jump;
  }
}
.icon-jump {
  &:before {
    content: $icon-jump;
  }
}
.components-icon-legend {
  &:before {
    content: $components-icon-legend;
  }
}
.icon-legend {
  &:before {
    content: $icon-legend;
  }
}
.components-icon-list {
  &:before {
    content: $components-icon-list;
  }
}
.icon-list {
  &:before {
    content: $icon-list;
  }
}
.components-icon-location-on {
  &:before {
    content: $components-icon-location-on;
  }
}
.icon-location {
  &:before {
    content: $icon-location;
  }
}
.components-icon-mail {
  &:before {
    content: $components-icon-mail;
  }
}
.icon-email-2 {
  &:before {
    content: $icon-email-2;
  }
}
.components-icon-menu-menu-default {
  &:before {
    content: $components-icon-menu-menu-default;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.components-icon-meter {
  &:before {
    content: $components-icon-meter;
  }
}
.icon-meter {
  &:before {
    content: $icon-meter;
  }
}
.components-icon-more_vert {
  &:before {
    content: $components-icon-more_vert;
  }
}
.components-icon-multi-view {
  &:before {
    content: $components-icon-multi-view;
  }
}
.icon-detach-window {
  &:before {
    content: $icon-detach-window;
  }
}
.components-icon-no-read-write {
  &:before {
    content: $components-icon-no-read-write;
    color: #e60c51;
  }
}
.components-icon-note {
  &:before {
    content: $components-icon-note;
  }
}
.icon-note {
  &:before {
    content: $icon-note;
  }
}
.components-icon-notifications {
  &:before {
    content: $components-icon-notifications;
  }
}
.icon-notifications {
  &:before {
    content: $icon-notifications;
  }
}
.components-icon-no-write {
  &:before {
    content: $components-icon-no-write;
    color: #e39b00;
  }
}
.components-icon-online-learning {
  &:before {
    content: $components-icon-online-learning;
    color: #6b7786;
  }
}
.icon-online-learning {
  &:before {
    content: $icon-online-learning;
    color: #6b7786;
  }
}
.icon-online-training {
  &:before {
    content: $icon-online-training;
    color: #6b7786;
  }
}
.components-icon-open {
  &:before {
    content: $components-icon-open;
  }
}
.icon-open {
  &:before {
    content: $icon-open;
  }
}
.components-icon-panviva {
  &:before {
    content: $components-icon-panviva;
  }
}
.icon-panviva-logo {
  &:before {
    content: $icon-panviva-logo;
  }
}
.components-icon-power {
  &:before {
    content: $components-icon-power;
  }
}
.icon-power {
  &:before {
    content: $icon-power;
  }
}
.components-icon-previous-document {
  &:before {
    content: $components-icon-previous-document;
  }
}
.icon-previous-document {
  &:before {
    content: $icon-previous-document;
  }
}
.components-icon-print {
  &:before {
    content: $components-icon-print;
  }
}
.icon-print {
  &:before {
    content: $icon-print;
  }
}
.components-icon-properties {
  &:before {
    content: $components-icon-properties;
  }
}
.icon-properties {
  &:before {
    content: $icon-properties;
  }
}
.components-icon-redo {
  &:before {
    content: $components-icon-redo;
  }
}
.icon-redo {
  &:before {
    content: $icon-redo;
  }
}
.components-icon-reference {
  &:before {
    content: $components-icon-reference;
  }
}
.icon-reference {
  &:before {
    content: $icon-reference;
  }
}
.components-icon-refresh {
  &:before {
    content: $components-icon-refresh;
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh;
  }
}
.components-icon-report {
  &:before {
    content: $components-icon-report;
  }
}
.icon-report {
  &:before {
    content: $icon-report;
  }
}
.components-icon-restricted {
  &:before {
    content: $components-icon-restricted;
    color: #e60c51;
  }
}
.icon-restricted {
  &:before {
    content: $icon-restricted;
    color: #e60c51;
  }
}
.components-icon-save {
  &:before {
    content: $components-icon-save;
  }
}
.icon-save {
  &:before {
    content: $icon-save;
  }
}
.components-icon-search {
  &:before {
    content: $components-icon-search;
  }
}
.icon-search-magnifying {
  &:before {
    content: $icon-search-magnifying;
  }
}
.components-icon-spell-check {
  &:before {
    content: $components-icon-spell-check;
  }
}
.icon-spell-check {
  &:before {
    content: $icon-spell-check;
  }
}
.components-icon-star {
  &:before {
    content: $components-icon-star;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
  }
}
.icon-rating {
  &:before {
    content: $icon-rating;
  }
}
.components-icon-star-fill {
  &:before {
    content: $components-icon-star-fill;
    color: #f9b625;
  }
}
.icon-star-fill {
  &:before {
    content: $icon-star-fill;
    color: #f9b625;
  }
}
.components-icon-task-path {
  &:before {
    content: $components-icon-task-path;
  }
}
.icon-task-path {
  &:before {
    content: $icon-task-path;
  }
}
.components-icon-task-screen {
  &:before {
    content: $components-icon-task-screen;
  }
}
.icon-task-type-screen {
  &:before {
    content: $icon-task-type-screen;
  }
}
.components-icon-task-transaction {
  &:before {
    content: $components-icon-task-transaction;
  }
}
.icon-task-transaction {
  &:before {
    content: $icon-task-transaction;
  }
}
.components-icon-tool-anchor {
  &:before {
    content: $components-icon-tool-anchor;
  }
}
.icon-anchor {
  &:before {
    content: $icon-anchor;
  }
}
.components-tool-bold {
  &:before {
    content: $components-tool-bold;
  }
}
.icon-bold {
  &:before {
    content: $icon-bold;
  }
}
.components-tool-buttonise {
  &:before {
    content: $components-tool-buttonise;
  }
}
.icon-buttonise {
  &:before {
    content: $icon-buttonise;
  }
}
.components-icon-tool-character {
  &:before {
    content: $components-icon-tool-character;
  }
}
.icon-character {
  &:before {
    content: $icon-character;
  }
}
.components-icon-tool-header {
  &:before {
    content: $components-icon-tool-header;
  }
}
.icon-header {
  &:before {
    content: $icon-header;
  }
}
.components-icon-tool-image {
  &:before {
    content: $components-icon-tool-image;
  }
}
.icon-image {
  &:before {
    content: $icon-image;
  }
}
.components-icon-tool-imagemap {
  &:before {
    content: $components-icon-tool-imagemap;
  }
}
.icon-imagemap {
  &:before {
    content: $icon-imagemap;
  }
}
.components-icon-tool-indent-decrease {
  &:before {
    content: $components-icon-tool-indent-decrease;
  }
}
.icon-indent-decrease {
  &:before {
    content: $icon-indent-decrease;
  }
}
.components-icon-tool-indent-increase {
  &:before {
    content: $components-icon-tool-indent-increase;
  }
}
.icon-indent-increase {
  &:before {
    content: $icon-indent-increase;
  }
}
.components-icon-tool-italic {
  &:before {
    content: $components-icon-tool-italic;
  }
}
.icon-italic {
  &:before {
    content: $icon-italic;
  }
}
.components-icon-tool-link {
  &:before {
    content: $components-icon-tool-link;
  }
}
.icon-link {
  &:before {
    content: $icon-link;
  }
}
.components-icon-tool-multiple-choice-radio {
  &:before {
    content: $components-icon-tool-multiple-choice-radio;
  }
}
.icon-multiple-choice-radio {
  &:before {
    content: $icon-multiple-choice-radio;
  }
}
.components-icon-tool-ordered-list {
  &:before {
    content: $components-icon-tool-ordered-list;
  }
}
.icon-ordered-list {
  &:before {
    content: $icon-ordered-list;
  }
}
.components-icon-tool-ordered-list-change {
  &:before {
    content: $components-icon-tool-ordered-list-change;
  }
}
.icon-ordered-list-change {
  &:before {
    content: $icon-ordered-list-change;
  }
}
.components-icon-tool-ordered-list-continue {
  &:before {
    content: $components-icon-tool-ordered-list-continue;
  }
}
.icon-ordered-list-continue {
  &:before {
    content: $icon-ordered-list-continue;
  }
}
.components-icon-tool-ordered-list-restart {
  &:before {
    content: $components-icon-tool-ordered-list-restart;
  }
}
.icon-ordered-list-restart {
  &:before {
    content: $icon-ordered-list-restart;
  }
}
.components-icon-tool-paragraph {
  &:before {
    content: $components-icon-tool-paragraph;
  }
}
.icon-paragraph {
  &:before {
    content: $icon-paragraph;
  }
}
.components-icon-tool-paragraph-centered {
  &:before {
    content: $components-icon-tool-paragraph-centered;
  }
}
.icon-paragraph-centered {
  &:before {
    content: $icon-paragraph-centered;
  }
}
.components-icon-tool-paragraph-insert-after {
  &:before {
    content: $components-icon-tool-paragraph-insert-after;
  }
}
.icon-paragraph-insert-after {
  &:before {
    content: $icon-paragraph-insert-after;
  }
}
.components-icon-tool-paragraph-insert-before {
  &:before {
    content: $components-icon-tool-paragraph-insert-before;
  }
}
.icon-paragraph-insert-before {
  &:before {
    content: $icon-paragraph-insert-before;
  }
}
.components-icon-tool-paragraph-left {
  &:before {
    content: $components-icon-tool-paragraph-left;
  }
}
.icon-paragraph-left {
  &:before {
    content: $icon-paragraph-left;
  }
}
.components-icon-tool-paragraph-right {
  &:before {
    content: $components-icon-tool-paragraph-right;
  }
}
.icon-paragraph-right {
  &:before {
    content: $icon-paragraph-right;
  }
}
.components-icon-tool-secondary-window {
  &:before {
    content: $components-icon-tool-secondary-window;
  }
}
.icon-secondary-window {
  &:before {
    content: $icon-secondary-window;
  }
}
.components-icon-tool-section {
  &:before {
    content: $components-icon-tool-section;
  }
}
.icon-section {
  &:before {
    content: $icon-section;
  }
}
.components-icon-tool-section-insert-after {
  &:before {
    content: $components-icon-tool-section-insert-after;
  }
}
.icon-section-insert-after2 {
  &:before {
    content: $icon-section-insert-after2;
  }
}
.components-icon-tool-section-insert-before {
  &:before {
    content: $components-icon-tool-section-insert-before;
  }
}
.icon-section-insert-before2 {
  &:before {
    content: $icon-section-insert-before2;
  }
}
.components-icon-tool-stock-text {
  &:before {
    content: $components-icon-tool-stock-text;
  }
}
.icon-stock-text {
  &:before {
    content: $icon-stock-text;
  }
}
.components-icon-tool-subheader {
  &:before {
    content: $components-icon-tool-subheader;
  }
}
.icon-subheader {
  &:before {
    content: $icon-subheader;
  }
}
.components-icon-tool-sub-unordered-list {
  &:before {
    content: $components-icon-tool-sub-unordered-list;
  }
}
.icon-sub-unordered-list {
  &:before {
    content: $icon-sub-unordered-list;
  }
}
.components-icon-tool-table {
  &:before {
    content: $components-icon-tool-table;
  }
}
.icon-table {
  &:before {
    content: $icon-table;
  }
}
.components-icon-tool-table-add-column-left {
  &:before {
    content: $components-icon-tool-table-add-column-left;
  }
}
.icon-table-add-column-left {
  &:before {
    content: $icon-table-add-column-left;
  }
}
.components-icon-tool-table-add-column-right {
  &:before {
    content: $components-icon-tool-table-add-column-right;
  }
}
.icon-table-add-column-right {
  &:before {
    content: $icon-table-add-column-right;
  }
}
.components-icon-tool-table-add-row-above {
  &:before {
    content: $components-icon-tool-table-add-row-above;
  }
}
.icon-table-add-row-above {
  &:before {
    content: $icon-table-add-row-above;
  }
}
.components-icon-tool-table-add-row-below {
  &:before {
    content: $components-icon-tool-table-add-row-below;
  }
}
.icon-table-add-row-below {
  &:before {
    content: $icon-table-add-row-below;
  }
}
.components-icon-tool-table-cell-merge-right {
  &:before {
    content: $components-icon-tool-table-cell-merge-right;
  }
}
.icon-table-cell-merge-right {
  &:before {
    content: $icon-table-cell-merge-right;
  }
}
.components-icon-tool-table-cell-split {
  &:before {
    content: $components-icon-tool-table-cell-split;
  }
}
.icon-table-cell-split {
  &:before {
    content: $icon-table-cell-split;
  }
}
.components-icon-tool-table-column-delete {
  &:before {
    content: $components-icon-tool-table-column-delete;
  }
}
.icon-table-column-delete {
  &:before {
    content: $icon-table-column-delete;
  }
}
.components-icon-tool-table-header-hide {
  &:before {
    content: $components-icon-tool-table-header-hide;
  }
}
.icon-table-header-hide {
  &:before {
    content: $icon-table-header-hide;
  }
}
.components-icon-tool-table-header-show {
  &:before {
    content: $components-icon-tool-table-header-show;
  }
}
.icon-table-header-show {
  &:before {
    content: $icon-table-header-show;
  }
}
.components-icon-tool-table-row-delete {
  &:before {
    content: $components-icon-tool-table-row-delete;
  }
}
.icon-table-row-delete {
  &:before {
    content: $icon-table-row-delete;
  }
}
.components-icon-tool-table-set-width-large {
  &:before {
    content: $components-icon-tool-table-set-width-large;
  }
}
.icon-table-set-width-large {
  &:before {
    content: $icon-table-set-width-large;
  }
}
.components-icon-tool-table-set-width-medium {
  &:before {
    content: $components-icon-tool-table-set-width-medium;
  }
}
.icon-table-set-width-medium {
  &:before {
    content: $icon-table-set-width-medium;
  }
}
.components-icon-tool-table-set-width-small {
  &:before {
    content: $components-icon-tool-table-set-width-small;
  }
}
.icon-table-set-width-small {
  &:before {
    content: $icon-table-set-width-small;
  }
}
.components-icon-tool-task {
  &:before {
    content: $components-icon-tool-task;
  }
}
.icon-task {
  &:before {
    content: $icon-task;
  }
}
.components-icon-tool-task-insert-after {
  &:before {
    content: $components-icon-tool-task-insert-after;
  }
}
.icon-task-insert-after {
  &:before {
    content: $icon-task-insert-after;
  }
}
.components-icon-tool-task-insert-before {
  &:before {
    content: $components-icon-tool-task-insert-before;
  }
}
.icon-task-insert-before {
  &:before {
    content: $icon-task-insert-before;
  }
}
.components-icon-tool-tooltip {
  &:before {
    content: $components-icon-tool-tooltip;
  }
}
.icon-popup {
  &:before {
    content: $icon-popup;
  }
}
.components-icon-tool-training-answer {
  &:before {
    content: $components-icon-tool-training-answer;
  }
}
.icon-training-answer {
  &:before {
    content: $icon-training-answer;
  }
}
.components-icon-tool-training-question {
  &:before {
    content: $components-icon-tool-training-question;
  }
}
.icon-training-question {
  &:before {
    content: $icon-training-question;
  }
}
.components-icon-tool-unlink {
  &:before {
    content: $components-icon-tool-unlink;
  }
}
.icon-unlink {
  &:before {
    content: $icon-unlink;
  }
}
.components-icon-tool-unordered-list {
  &:before {
    content: $components-icon-tool-unordered-list;
  }
}
.icon-unordered-list {
  &:before {
    content: $icon-unordered-list;
  }
}
.components-icon-tool-verbatim {
  &:before {
    content: $components-icon-tool-verbatim;
  }
}
.icon-verbatim {
  &:before {
    content: $icon-verbatim;
  }
}
.components-icon-trans-complete {
  &:before {
    content: $components-icon-trans-complete;
    color: #76ca02;
  }
}
.components-icon-trans-incomplete {
  &:before {
    content: $components-icon-trans-incomplete;
    color: #e60c51;
  }
}
.components-icon-unarchive {
  &:before {
    content: $components-icon-unarchive;
  }
}
.icon-unarchive {
  &:before {
    content: $icon-unarchive;
  }
}
.components-icon-undo {
  &:before {
    content: $components-icon-undo;
  }
}
.icon-undo {
  &:before {
    content: $icon-undo;
  }
}
.components-icon-upload {
  &:before {
    content: $components-icon-upload;
  }
}
.icon-upload {
  &:before {
    content: $icon-upload;
  }
}
.components-icon-user {
  &:before {
    content: $components-icon-user;
  }
}
.icon-user2 {
  &:before {
    content: $icon-user2;
  }
}
.components-icon-task-who {
  &:before {
    content: $components-icon-task-who;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
.components-icon-warning {
  &:before {
    content: $components-icon-warning;
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;
  }
}
.components-icon-no-write-filled {
  &:before {
    content: $components-icon-no-write-filled;
    color: #e39b00;
  }
}
.icon-unwriteable {
  &:before {
    content: $icon-unwriteable;
    color: #e39b00;
  }
}
.components-icon-no-read-write-filled {
  &:before {
    content: $components-icon-no-read-write-filled;
    color: #e60c51;
  }
}
.icon-unreadable {
  &:before {
    content: $icon-unreadable;
    color: #e60c51;
  }
}
.components-icon-document-default {
  &:before {
    content: $components-icon-document-default;
  }
}
.components-icon-tool-document {
  &:before {
    content: $components-icon-tool-document;
  }
}
.components-icon-comments {
  &:before {
    content: $components-icon-comments;
  }
}
.icon-comments {
  &:before {
    content: $icon-comments;
  }
}
.components-icon-announcements {
  &:before {
    content: $components-icon-announcements;
  }
}
.icon-megaphone {
  &:before {
    content: $icon-megaphone;
  }
}
