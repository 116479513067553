@import '../../../../styles/_settings';

.source-search {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: $pv-background-sub-colour;
    margin: 20px;
    border-radius: 5px;

    .source-search-container{
        width: 400px;
        padding: 15px;
        margin: 0 auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: baseline;
        gap: 1px;
    }

    .source-search-back{
        display: flex;
        margin: 15px;
    }
}