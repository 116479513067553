@import "../../../../styles/_settings";
@import "../../../../styles/_tables";
@import "../../../../styles/variables";

.composite-response-list {
  padding-right: 25px;
  @extend %table;
  .table {
    width: 100%;
    display: table;
    thead {
      th {

        &.title {
          width: 100%;
        }

        &.category {
          width: 150px;
        }

        &.status {
          width: 100px;
        }

        @include media-breakpoint-up(xl) {
          // Styles for width above 1200px
          &.category {
             min-width: 200px;
          }

          &.status {
            min-width: 200px;
          }
        }
      }
    }
  }
}
