@import '../../../../styles/variables';

.filter-title{
    margin-bottom: 10%;
    font-weight: bold;
}

.text-field{
    display: flex;
    min-width: 200;
    font-size: 12;
}

.filter-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
}

.menu-item {
    display: flex;
    padding: 5px;
    &:hover{
        background-color: $dropwdown-hover-bg;
    }
    .menu-label{
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        &:hover{
            cursor: pointer;
        }
    }
}

.date-filter-popover{
    overflow: visible !important;
    min-width: 220px;

    .date-filter-container{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

.select-menu-chips{
    margin: 2px;
    max-width: 175px;
}