@import "../../../../styles/_settings";

.section-selector-container {
  margin-top: 34px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .invalid-feedback {
    min-height: 20px;
    display: block;
  }

  .border-bottom {
    border-bottom: solid 1px red;
  }

  .curation-block {
    padding: 5px;
    border-top: 1px solid black;
    border-right: 1px solid black;
    border-left: 1px solid black;

    .wandicon {
      margin-top: -1px;
      margin-left: -1px;
      display: inline-block;
    }
    .enabled-wandicon {
      margin-top: -9px;
      margin-left: -10px;
    }

    .greyWandicon {
      margin-top: -9px;
      margin-left: -10px;
    }

    .EnabledUndoIcon {
      &:hover { 
      cursor: pointer;
      }
    }
  }

  .padding-3 {
    padding: 3px;
  }

  .font-12 {
    font-size: 12px !important;
  }

  .shadow-active{
    &:focus{
      box-shadow: none !important;
    }
    &:active{
      box-shadow: 0 0 0 0.2rem rgb(148 152 156 / 50%) !important;
    }
  }

  .disclaimer-text {
    font-size: 10px;
    line-height: 12px;
    height: 45px;
  }

  .margin-right-2 {
    margin-right: 2px;
  }

  .padding-top-1 {
    padding-top: 1px;
  }

  .disabled-button {
    background-color: #e4e5e6;
    color: #485056;
    border: none;
    opacity: 0.65;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.5;
    border-radius: 0.25rem;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    letter-spacing: 1.5px;
  }

  .section-selector {
    flex: 1;
    width: 100%;
    scroll-behavior: smooth;
    background-color: $sectionselect-bg;
    overflow: auto;
    border: 1px solid $pv-border-darker-colour;

    &.is-invalid {
      border: 1px solid $required-flag-color;
    }

    ul {
      list-style: none;
      padding: 0;
      margin-top: 10px;

      li {
        &.custom-control.custom-checkbox {
            padding-left: 40px;
            display: flex;
            margin-top: 10px;
            align-items: center;

            &.checked {
                background-color: $pv-blue10;
            }
        }

        label.custom-control-label {
          padding: 8px 12px;
          border: 1px solid transparent;
          display: block;
          margin: 5px;
          font-size: 12px;
          line-height: 15px;
          color: $selectdoc-input-color;
          border-radius: 6px;

          &:before,
          &:after {
            top: 50%;
            left: -28px;
            width: 18px;
            height: 18px;
            transform: perspective(1px) translateY(-50%);
          }

          &:hover {
            border-color: $sectionselect-hover-bg;
          }

          &.has-changes {
            border: solid 1px $pv-danger-colour;
            transition: box-shadow 0.6s ease-in-out;
            box-shadow: 0 rgba(216, 51, 51, 0.15);

            &.animate {
              box-shadow: 0 3px 3px rgba(221, 54, 54, 0.4);
            }
          }
        }

        .custom-control-input{
          &:focus {
            box-shadow: 0 0 0 .2rem rgba(0,123,255,.5);
          }
          &:hover ~ .custom-control-label{
            border-color: $sectionselect-hover-bg;
          }
        }

        .custom-control-input ~ .custom-control-label::before {
          background-color: $sectionselect-checkbox-bg;
          border: solid 2px $pv-border-darker-colour;
          border-radius: 3px;
        }

        .custom-control-input:checked ~ .custom-control-label {
          &::before {
            background-color: $pv-primary-colour;
            background-size: 30px 30px;
            border: solid 2px $pv-primary-colour;
          }

          &::after {
            background-size: 12px 12px;
          }
        }

        p {
          font-size: 12px;
          padding: 0;
          margin: 0 0 5px 0;
        }

        table {
          .pv-bold {
            font-weight: bold;
          }

          font-size: 14px;
          border-top: solid 1px #d8d8d8;
          border-bottom: solid 1px #d8d8d8;
          width: 100%;
          padding: 5px 0;

          td,
          th {
            padding: 0 0 10px 10px;
          }

          td:first-child {
            padding-top: 10px;
          }
        }
      }
    }
    }

    .pv-image,
    .pv-image-map {
      max-width: 100%;
    }
}
