@import './_settings';
@import "_font_variables";
@import '~bootstrap/scss/_functions';

@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';


:root{
  --bs-btn-active-bg : $pv-background-colour;
  --bs-btn-active-color: $pv-primary-colour;
}

*,
*:before,
*:after {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, $icomoon-font-family, 'Font Awesome 6 Free' !important;
}

html,
body,
#root {
  height: 100%;
  font-size: 14px;

}

h1 {
  &.title {
    margin-bottom: 25px;
    margin-top: 25px;
    font-size: 18px;
    font-weight: 600;
  }
}

body {
    background-color: $body-background-color;
    font-family: $font-family;
    color: $text-color;
    background-color: $panviva-darkgray;
}

main {
  flex: 1;
  display: block;
  background-color: $pv-background-sub-colour;
}

a {
  text-decoration: none;
}

.react-tooltip.cc-tooltip {
    z-index: 999;
    background-color: $tooltip-bg-color;
    font-weight: 500;
    border-radius: 4px;
    max-width: 200px;
    --rt-opacity: 1;
    font-size: 13px;

  &.react-tooltip__place-right:after {
    border-right-color: $tooltip-bg-color;
    left: -5px;
  }
 
}

.btn, .btn-primary, .btn-outline-secondary {
  &:disabled {
    background-color: $btn-disabled-bg-color !important;
    color: $btn-disabled-text-color !important;
    border: none;

    &:hover {
      background-color: $btn-disabled-bg-color;
      color: $btn-disabled-text-color;
    }
  }

  &:hover {
    color: $pv-light-colour;
    background-color: $panviva-border-gray;
    border-color: $panviva-border-gray;
  }
}

.btn-outline-danger{
  color: $pv-danger-colour !important;
  border-color:$pv-danger-colour !important;
  &:focus{
    box-shadow: 0 0 0 0.2rem rgb(197 37 93 / 50%) !important;
  }
}
.btn-danger{
  color: $pv-light-colour !important;
  border-color:$pv-danger-colour !important;
  background-color: $pv-danger-colour !important;
}

.btn-primary {
  background-color: $pv-primary-colour;
  color: $btn-primary-text-color;
  font-weight: 500;
  border: none;

  &:hover {
    background-color: $btn-primary-hover-bg-color;
    color: $btn-primary-text-color;
  }
}


.page-wrapper {
  min-height: 100%;
  background-color: white;
  padding: 0 0;
  display: flex;
  flex-direction: column;
}

.main-body {
  display: flex;
  padding-left: 0;
  padding-right: 0;
  flex: auto 1 1;
  background-color: $pv-background-sub-colour;

  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fa:hover{
  cursor: pointer !important;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.navbar {
  border-bottom: 4px solid dodgerblue;
}

.error-message {
  margin-top: 20px;
  padding: 0 20px;

  &:first-child{
    margin-top: 230px;
  }
}

 .msg-tooltip-icon {
     color: $panviva-navy;
 }

 .alert-danger {
  color: $pv-danger-colour;
  background-color: #f8d7da;
  border-color: #f5c6cb;
 }

 .invalid-feedback{
  color: $pv-danger-colour !important;
 }

 .clearfix{
   height: 5px;
 }

 img{
  display: inline !important;
 }