@import '../../../../styles/_settings';

.response-tags {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .add-tags {
        flex-basis: 25%;
        min-width: 200px;
        margin-right: 10px;
        margin-bottom: 10px;
        height: 38px;

        .form-control {
          height: 38px;
          border: 1px solid $panviva-border-gray;
        }
        .btn {
            background-color: $pv-light-colour;
            border: 1px solid $pv-primary-colour;
            border-radius: 0 4px 4px 0;
            height: 38px;
            color: $pv-primary-colour;
        }
    }

    .tag {
        margin-top: 5px;
        margin-right: 10px;
        margin-left: 0;
        height: 31px;
        padding: 11px;
        display: inline-flex;
        align-items: center;
        background-color: $pv-tag-bg-colour;
        border-radius: 20px;
        font-size: 14px;
        color: $pv-text-colour-80;
        border: 1px solid $pv-text-colour-80;

        .fa, .icon {
            cursor: pointer;
            margin-left: 5px;
        }
    }
}