@import '../../../styles/_settings';
@import '../../../styles/_tables';

.drafts-responses {
  .up-to-date-modal {
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
  }
  .title-link{
    color: $pv-primary-colour;
  }
  position: relative;
  width: 100%;

  @extend %table;

  .table {
    thead {
      th {
        title {
          width: 100%;
        }
      }
    }
    tr{
      height: 47px;
      font-size: 14px;
      border-bottom: .5px solid $panviva-border-gray;

    }
  }
}

