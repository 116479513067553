@import '../../../../styles/_settings';

.select-document {
  display: flex;
  height: 752px;
  margin-bottom: 30px;

  .panel-flex {
    flex: 1;
    width: 48%;

    .textarea-container {
      flex: 1;
      margin-top: 34px;
      display: flex;
      flex-direction: column;

      textarea {
        flex: 1;
        border: 1px solid $pv-border-darker-colour;
        box-sizing: border-box;
        border-radius: 0;
        resize: none;

        font-size: 12px;
        line-height: 20px;
        color: $selectdoc-input-color;

        &.is-invalid {
          border: 1px solid $required-flag-color;
        }
      }

      .invalid-feedback {
        display: block;
        min-height: 20px;
      }

      .border-bottom {
        border-bottom: solid 1px red;
      }

      .disabled-button {
        background-color: #E4E5E6;
        color: #485056;
        border: none;
        opacity: 0.65;
        text-transform: uppercase;
        font-weight: 500;
        line-height: 1.5;
        border-radius: 0.25rem;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        letter-spacing: 1.5px
      }

      .curation-block {
        padding: 5px;
        border-right: 1px solid black;
        border-left: 1px solid black;
        border-top: 1px solid black;

        .wandicon {
          display: inline-block;
        }

        .enabled-wandicon {
          margin-top: -9px;
          margin-left: -10px;
        }

        .greyWandicon {
          margin-top: -9px;
          margin-left: -10px;
        }
        
        .EnabledUndoIcon {
          &:hover { 
          cursor: pointer;
          }
        }
      }

      .padding-3 {
        padding: 3px;
      }

      .font-12 {
        font-size: 12px !important;
      }

      .shadow-active{
        &:focus{
          box-shadow: none !important;
        }
        &:active{
          box-shadow: 0 0 0 0.2rem rgb(148 152 156 / 50%) !important;
        }
      }

      .disclaimer-text{
        font-size: 10px;
        line-height: 12px;
        height: 45px;
      }

      .margin-right-2{
        margin-right: 2px;
      }

      .padding-top-1{
        padding-top: 1px;
      }
    }
  }

  .panel-middle {
    flex: 0 0 72px;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      background-color: $pv-primary-colour;
      color: $selectdoc-copybtn-color;

      .double-chevron {
        margin-right: -3px;

        i:last-child {
          margin-left: -3px;
        }
      }
    }
  }

  .section-deleted-notification {
    height: 40px;
    margin: 12px 10px 0px 10px;
    border: 1px solid $notification-border-color;
    border-radius: 4px;
    background-color: $notification-bg-color;
    font-size: 12px;
    line-height: 15px;

    i.fa,
    i.icon {
      float: left;
      display: block;
      width: 16px;
      font-size: 14px;
      margin: 12px 15px;
      text-align: center;
    }

    .notification-text {
      font-size: 12px;
      padding: 5px 0px 3px 0px;
    }
  }

  .document-deleted-notification,
  .untaggable-document-notification {
    width: 90%;
    margin: 40% auto;
    padding: 10px 20px;
    border: 1px solid $notification-border-color;
    border-radius: 4px;
    background-color: $notification-bg-color;

    .notification-message {
      padding: 0px;
    }
  }
}