@import '../../../styles/_settings';
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

nav.sidebar {
    background-color: $pv-background-colour;
    flex: 0 0 56px;
    border-right: 1px solid $panviva-border-gray;
    border-bottom: 10px solid $pv-primary-colour;
    transition: width 2s;

    &.expanded{
        min-width: 209px;
        width: 209px;

        ul a .link-text{
            display: block;
        }
    }
    @include media-breakpoint-down(sm) {
        display: none;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 8px;

        a {
            color: $pv-icon-colour;
            display: block;
            height: 48px;
            text-align: center;
            padding-top: 10px;
            border-left: 4px solid #FFF;

            .link-text {
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                display: none;
                text-align: left;
                float: left;
                margin-left: 60px;
                margin-top: -21px;
            }

            &.active {
                background-color: $sidebar-highlight-color;
                color: $pv-primary-colour;
                text-decoration: none;
                border-left: 4px solid $pv-primary-colour;
            }

            &:hover {
                //color: $pv-primary-colour;
                text-decoration: none;
            }

            i {
                display: block;
                font-size: 26px;
                width: 51px;
            }
        }
    }
}